<template>
    <article>
        <div class="modal-backdrop"></div>
        <div class="modal fade show d-block" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                  <b-overlay :show="overlay">
                    <form @submit.prevent="forwardMessage">
                      <div class="modal-header">
                        <h5>Forward Message</h5>
                        <button @click="bus.$emit('forwardMessage', null)" type="button" class="close" style="padding: 5px 10px;">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div class="modal-body leftchat-panel" style="width: 100%">
                        <div class="user-chat-lists forward-conv-list" style="height: auto">
                          <ul v-if="conversationList.length > 0" class="chat-list">
                            <li v-for="conversation in conversationList" :key="conversation.id" class="show" :style="{background: selectedConversations.indexOf(conversation.id) != -1?'#efefef':''}">
                              <label style="width: 100%">
                                <input type="checkbox" class="form-check-input" v-model="selectedConversations" :value="conversation.id" style="height: 40px;">
                                <div class="chat-item forward-chat-item">
                                  <div class="dropdown chat-avatar">
                                    <img class="profile-image avatar" width="46" :src="conversation.contacts.display_picture">
                                  </div>
                                  <div class="chat-user-ct" style="padding: 10px 70px 20px 0">
                                    <span class="chat-user-name">{{ conversation.contacts.name }}</span>
                                  </div>
                                </div>
                              </label>
                            </li>
                          </ul>
                          <div v-if="conversationList.length == 0" class="text-center">
                            <img src="/images/logo-grey.png" class="img-responsive"/>
                            <p>Looks like you no active conversations :(</p>
                          </div>
                        </div>
                      </div>
                      <div class="modal-footer">
                        <button class="btn btn-primary">Send  <i class="fas fa-paper-plane"></i> </button>
                      </div>
                    </form>
                  </b-overlay>
                </div>
            </div>
        </div>
    </article>
</template>

<script>
export default {
    name: "MessageForward",
    props: ['bus','messageId'],
    data() {
        return {
            conversationList: {},
            selectedConversations: [],
            overlay: false
        }
    },
    mounted() {
        this.$http.get('/web/conversation/forward/list').then(response => {
            if(response.data.result) {
                this.conversationList = response.data.data;
            } else {
                //toastr.error(response.data.message);
            }
        });
    },
    methods: {
        forwardMessage: function () {
            if(this.selectedConversations.length > 0) {
              this.overlay = true;
                this.$http.post('/web/message/forward', {
                    msg_id: this.messageId,
                    conversations: this.selectedConversations
                }).then(response => {
                  this.overlay = false;
                    if(response.data.result) {
                        this.bus.$emit('refreshAfterForward', {
                            message: response.data.data,
                            conversationIds: response.data.conversations
                        })
                      this.$bvToast.toast(response.data.message, {
                        variant: 'success',
                        title: 'Success'
                      });
                    } else {
                        this.bus.$emit('forwardMessage', null)
                      this.$bvToast.toast(`Failed to forward message`, {
                        variant: 'danger',
                        title: 'Error'
                      })
                    }
                });
            } else {
                //toastr.error("No conversation selected");
            }
        }
    }
}
</script>

<style scoped>
    .chat-panel .leftchat-panel {
        max-height: 300px;
    }
</style>
