<template>
  <div>
    <b-sidebar id="sidebar-no-header" aria-labelledby="sidebar-no-header-title" no-header shadow>
      <template #footer="{ hide }">
        <div class="d-flex text-light align-items-center px-3 py-2 border-top">
          <strong class="mr-auto"><img width="27" src="@/assets/images/logo-transperant.png" /></strong>
          <b-button size="sm" @click="hide">Close</b-button>
        </div>
      </template>
      <template #default>
        <div class="p-3">
          <div class="user-chat-lists">
            <div class="chat-account-settings-heading">
              <h4> {{ accountInfo.name }}</h4>
              <p> {{ accountInfo.role }} Account</p>
            </div>
            <div class="chat-account-settings access-denied">
              <div class="assign-conversation-overlay">
                <div class="handler">
                  <div class="box-btn status">
                    <span>Do You Want To assign This<br>conversation to you ?</span>
                  </div>
                  <div class="bl-space-bt">
                    <a href="javascript:void(0);" class="box-btn btn-yes">YES</a>
                    <a href="javascript:void(0);" class="box-btn btn-no">NO</a>
                  </div>
                </div>
              </div>
              <label class=" radio-block">
                <b-icon-gear-fill/>
                <span>Account Settings</span>
              </label>
            </div>

            <div class="chat-account-settings">
              <label class=" radio-block">
                <b-icon-folder-fill/>
                <span>Archived messages</span>
              </label>
            </div>

            <div class="chat-account-settings">
              <label class=" radio-block">
                <b-icon-tags-fill/>
                <span>Tagged messages</span>
              </label>
            </div>

            <div class="chat-account-settings">
              <label class=" radio-block">
                <b-icon-moon/>
                <span>DARK MODE</span>
                <div class="switch">
                  <input type="checkbox" checked="">
                  <div class="slider round"></div>
                </div>
              </label>
            </div>

            <div class="chat-account-settings">
              <label class=" radio-block">
                <b-icon-bell-fill/>
                <span>NOTIFICATIONS</span>
              </label>
            </div>
            <div class="chat-account-settings">
              <label class="radio-block">
                <b-icon-info-circle-fill/>
                <span>HELP</span>
              </label>
            </div>
            <div class="chat-account-settings">
              <label class="radio-block" @click="bus.$emit('logout', true)">
                <b-icon-lock-fill/>
                <span>Log OUt</span>
              </label>
            </div>
          </div>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
export default {
  name: "SidebarComponent",
  props: ['bus'],
  data() {
    return {
      accountInfo: [],
      logoutUrl: 'logout'
    }
  },
  mounted() {
    if (localStorage.getItem("session")  !== null) {
      this.accountInfo = JSON.parse(localStorage.getItem('session'))
    }
  }
}
</script>

<style scoped>

</style>