<template>
    <div>
        <div class="contact-type" @click="contactPreview=true">
            <span class="b-avatar mr-1 badge-secondary rounded-circle">
                <b-icon-person-square class="contact-icon"/>
            </span>
            <span class="contact-name">{{ name }}</span>
        </div>
        <b-modal v-if="contactPreview" v-model="contactPreview" hide-footer
                 :no-close-on-backdrop="true"
                 :body-class="'photo-preview-modal'" :title="name">
            <div class="text-center">
                <table class="table">
                    <tr class="text-left">
                        <th>Phone</th>
                        <th>Type</th>
                    </tr>
                    <tr v-for="(contact,i) in contactItems" :key="i" class="text-left">
                        <td>{{ contact.Number }}</td>
                        <td>{{ contact.Type }}</td>
                    </tr>
                </table>
            </div>
        </b-modal>
    </div>
</template>
<script>
export default {
    name: "ContactType",
    props: ['message'],
    data() {
        return {
            name: null,
            contactPreview: false,
            contactItems: []
        }
    },
    mounted() {
        let contact = JSON.parse(this.message.contact_card)
        this.name = contact[0].name.formatted_name
        contact[0].phones.map( phone => {
            this.contactItems.push({
                "Number" : phone.phone,
                "Type" : phone.type
            });
        })
    }
}
</script>

<style scoped>
.contact-type {
    min-width: 150px;
    background: #6c757d;
    border-radius: 18px;
    padding-right: 8px;
    padding-left: 8px;
    cursor: pointer;
}
.contact-name {
    font-size: 15px;
}
</style>