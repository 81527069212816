<template>
    <div class="user-act-btm usr-templates" style="display: block;">
        <div class="tag-area">
            <div v-for="template in templates" :key="template.id" class="tag-select template">
                <div v-if="template.type === 'text'" @click="$emit('sendTemplate', template)">{{ template.templates }}</div>
                <div v-if="template.type === 'document'" @click="$refs['fileTemplate' + template.id][0].click()">
                    <b-icon-file-arrow-up-fill v-if="template.type === 'document'"/> {{ template.templates }}
                </div>
                <input type="file" style="display: none;" name="media" :ref="'fileTemplate' + template.id" @change="$emit('sendTemplateMedia',{event: $event, templateId: template.id})"/>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "MessageTemplates",
    data() {
        return {
            templates: []
        }
    },
    mounted() {
        this.$http.get("/message/templates")
            .then( response => {
                if(response.data.result) {
                    this.templates = response.data.data;
                } else {
                    this.toast("Failed to fetch templates")
                }
            })
            .catch( error => this.errorHandler(error))
    }
}
</script>

<style scoped>

</style>