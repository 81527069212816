<template>
    <div>
        <b-list-group v-if="!openForm">
            <b-list-group-item v-for="ut in userTemplates" @click="userTemplateForm(ut)" :key="ut.id" button>{{ut.template}}</b-list-group-item>
        </b-list-group>
        <user-template-fields v-if="openForm" @receiveUserTemplate="sendMessage" :template="this.template"/>
    </div>

</template>

<script>
import UserTemplateFields from "./UserTemplateFields";
export default {
    name: "UserTemplatesComponent",
    components: {UserTemplateFields},
    data() {
        return {
            userTemplates: [],
            openForm: false,
            template: []
        }
    },
    mounted() {
        this.fetchTemplates()
    },
    methods: {
        fetchTemplates: function () {
            this.$http.get("/message/user/templates")
                .then( response => {
                    if(response.data.result) {
                        this.userTemplates = response.data.data;
                    } else {
                        this.toast("Failed to fetch templates")
                    }
                })
                .catch( error => this.errorHandler(error))
        },
        userTemplateForm(template) {
            this.template = template;
            this.openForm = true;
        },
        sendMessage(message) {
            this.$emit('receiveUserTemplate', message)
        }
    }
}
</script>

<style scoped>

</style>