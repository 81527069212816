<template>
    <div>
        <b-overlay :show="overlay">
            <b-form @submit.stop.prevent="onSubmit">
                <b-form-group>
                    <b-form-input
                        v-model="$v.form.name.$model"
                        :state="validateState('name')"
                        placeholder="Name"
                    ></b-form-input>
                    <b-form-invalid-feedback>This is a required field and must be at least 2 characters.
                    </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group>
                    <b-form-input
                        v-model="$v.form.display_name.$model"
                        :state="validateState('display_name')"
                        placeholder="Display Name"
                    ></b-form-input>
                    <b-form-invalid-feedback>This is a required field and must be at least 2 characters.
                    </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group>
                    <b-form-input
                        v-model="$v.form.email.$model"
                        :state="validateState('email')"
                        placeholder="Email"
                    ></b-form-input>

                    <b-form-invalid-feedback>This is a required field and must be a valid email address.
                    </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group>
                    <b-row>
                        <b-col>
                            <vue-tel-input v-if="phoneInput" :autoDefaultCountry="false"
                                :defaultCountry="parseInt(form.phone_code)"
                                :preferredCountries="['SA','AE']"
                                v-model="$v.form.phone_number.$model"
                                :state="validateState('phone_number')"
                                :auto-format="false"
                                v-on:country-changed="countryChange"
                                @open="onDropdownOpen(true)"
                                @close="onDropdownOpen(false)"
                                required
                            >
                                <template v-slot:arrow-icon>
                                    <span>{{ open ? '▲' : '▼' }}</span>
                                </template>
                            </vue-tel-input>
                            <b-form-invalid-feedback>This is a required field and must be a valid number.
                            </b-form-invalid-feedback>
                        </b-col>
                        <!--            <b-col>
                                      <b-form-input
                                          v-model="$v.form.phone_number.$model"
                                          :state="validateState('phone_number')"
                                          placeholder="Phone Number"
                                      ></b-form-input>
                                      <b-form-invalid-feedback>This is a required field and must be a valid number.</b-form-invalid-feedback>
                                    </b-col>-->
                    </b-row>
                </b-form-group>

                <b-button type="submit" variant="primary">Submit</b-button>
                <b-button class="ml-2" @click="resetForm()">Reset</b-button>
            </b-form>
        </b-overlay>
    </div>
</template>

<script>
import {validationMixin} from "vuelidate";
import {required, minLength, email, numeric} from "vuelidate/lib/validators";
import {VueTelInput} from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';

export default {
    name: "ContactForm",
    mixins: [validationMixin],
    components: {VueTelInput},
    props: ['bus', 'contactId'],
    data() {
        return {
            autoDefaultCountry: false,
            open: false,
            overlay: false,
            phoneInput: false,
            session: null,
            form: {
                name: null,
                display_name: null,
                email: null,
                phone_code: null,
                phone_number: null
            }
        };
    },
    mounted() {
        if (localStorage.getItem("session")  !== null) {
            this.session = JSON.parse(localStorage.getItem('session'))
        }
        if (this.contactId !== null) {
            this.overlay = true;
            this.$http.post(`/contact/get/${this.contactId}`)
                .then(response => {
                    this.overlay = false;
                    if (response.data.result) {
                        this.form = {
                            name: response.data.data.name,
                            display_name: response.data.data.display_name,
                            email: response.data.data.email,
                            phone_code: parseInt(response.data.data.phone_code),
                            phone_number: response.data.data.phone_number
                        }
                        this.phoneInput = true;
                    } else {
                        this.toast(response.data.error.message, 'error');
                    }
                })
                .catch(error => {
                    this.overlay = false;
                    this.errorHandler( error );
                    this.toast("There was an error", "error");
                })
        } else {
            this.phoneInput = true;
        }
    },
    validations: {
        form: {
            name: {
                required,
                minLength: minLength(2)
            },
            display_name: {
                required,
                minLength: minLength(2)
            },
            email: {
                email
            },
            phone_number: {
                required,
                numeric
            }
        }
    },
    methods: {
        countryChange: function (phone) {
            this.form.phone_code = phone.dialCode;
        },
        onDropdownOpen: function (val) {
            this.open = val
        },
        validateState(name) {
            const {$dirty, $error} = this.$v.form[name];
            return $dirty ? !$error : null;
        },
        resetForm() {
            this.form = {
                name: null,
                display_name: null,
                email: null,
                phone_code: null,
                phone_number: null
            };

            this.$nextTick(() => {
                this.$v.$reset();
            });
        },
        onSubmit() {
            this.$v.form.$touch();
            if (this.$v.form.$anyError) {
                return;
            }
            this.overlay = true;
            let url = '/contact/create';
            if (this.contactId !== null) {
                url = `/contact/edit/${this.contactId}`;
            }
            this.$http.post(url, this.form)
                .then(response => {
                    this.resetForm();
                    this.overlay = false;
                    if (response.data.result) {
                        this.MixPanelTrack("Contact Added", {
                            "Contact Name": response.data.data.name,
                            "$name": this.session.name,
                            "Organization": this.Organization(this.session.organization_phone)
                        });
                        this.bus.$emit('contactsReload', this.message);
                    } else {
                        this.$bvToast.toast(response.data.error.message, {
                            variant: 'error'
                        })
                    }

                })
                .catch(error => {
                    this.overlay = true;
                    this.errorHandler(error);
                    this.toast("There was an error");
                });
        }
    }
};
</script>