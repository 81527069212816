<template>
  <div>
<!--    <div class="router-switch">
      <router-link v-if="session.role == 'Manager'" to="/manager"><b-icon-house-fill/> Manager Home</router-link>
    </div>-->
    <section id="conversation-panel" class="chat-panel" v-bind:class="[toggle.threePanel ? 'three-chat-panel':'', session.role === 'Agent' ? 'user-panel':'']">
      <aside class="leftchat-panel conversation-panel">
        <conversation-component :bus="bus"></conversation-component>
        <contacts-component v-if="toggle.contact" :bus="bus" :session="session"></contacts-component>
<!--        <account-component v-if="toggle.account" :bus="bus"></account-component>-->
        <footer class="chat-panel-btm">
          <!-- <router-link v-if="session.role == 'Manager'" to="/manager"><b-icon-house/></router-link>
          <a href="javascript:void(0);" :class="toggle.account ? '' : 'select'" @click="toggle.account = false"><b-icon-chat-dots/></a>
          <a href="javascript:void(0);" :class="toggle.account ? 'select' : ''" @click="toggle.account = true"><b-icon-person/></a> -->
          <a href="javascript:void(0);" :class="toggle.account ? 'select' : ''" v-b-toggle.sidebar-no-header><b-icon-list/></a>
        </footer>
      </aside>
      <empty-chat-window v-if="Object.keys(activeConversation).length === 0"></empty-chat-window>
      <chat-component v-if="refreshChat" :bus="bus" :contactInfo="toggle.threePanel" ref="ChatComponent" :key="activeConversation.id" :conversation="activeConversation"></chat-component>
      <login-component :bus="bus" v-if="Object.keys(this.session).length === 0"></login-component>
      <b-modal v-model="toggle.tagsModal" hide-footer title="Tags">
        <form @submit.prevent="saveTags" class="user-tag-ct show">
          <ul>
            <li v-for="tag in session.tags" :key="tag.id">
              <label class="tag-name">
                <input :value="tag.id" type="checkbox" v-model="selectedTags">
                <span class="checkmark"><div :style="{'background': tag.colour}" class="label-colour-dot"></div>{{ tag.name }}</span>
              </label>
            </li>
          </ul>
          <button class="btn btn-primary btn-apply apply-tags">Apply</button>
        </form>
      </b-modal>
    </section>
  </div>

</template>

<script>
import ContactsComponent from "../components/ContactsComponent";
import LoginComponent from "../components/LoginComponent";
import ConversationComponent from "../components/ConversationComponent";
/*import AccountComponent from "../components/AccountComponent";*/
import ChatComponent from "../components/ChatComponent";
import EmptyChatWindow from "../components/EmptyChatWindow";
/*import SidebarComponent from "../components/SidebarComponent";*/

export default {
  name: 'App',
  props: ['bus'],
  components: {
    //SidebarComponent,
    LoginComponent, ConversationComponent, ContactsComponent, ChatComponent, EmptyChatWindow
  },
  data() {
    return {
      session: {},
      toggle: {
        contact: false,
        threePanel: false,
        account: false,
        activeLabelsDropdown: '',
        activeSelectedLabels: [],
        tagsModal: false,
      },
      refreshChat: true,
      forwardMessageId: null,
      activeConversation: {},
      selectedTags: [],
      selectedTagMessageId: null,
    };
  },
  mounted() {
    if (localStorage.getItem("session")  !== null) {
      this.session = JSON.parse(localStorage.getItem('session'))
    }
    console.info(`Session: ${this.session.role}`);
    this.bus.$on('setSession', (session) => {this.session = session;});
    this.bus.$on('openContacts', (value) => {
      this.toggle.contact = value;
    });
    this.bus.$on('openFilters', (value) => {
      this.toggle.filters = value;
    })
    this.bus.$on('logout', () => {
      localStorage.removeItem('session');
      this.session = {};
      this.toggle.account = false;
      location.reload();
    })
    this.bus.$on('activeConversation', (conversation) => {
      this.activeConversation = conversation
    });
    this.bus.$on('toggleThreePanel', (value) => {this.toggle.threePanel = value;});
    this.bus.$on('tagsModel', (message) => {
      this.selectedTags = [];
      this.toggle.tagsModal = true;
      for(let i=0; i < message.tags.length; i++) {
        this.selectedTags.push(message.tags[i].tag_id);
      }
      this.selectedTagMessageId = message.id;
    });
  },
  methods: {
    saveTags: function () {
      let data = {
        msg_id: this.selectedTagMessageId,
        tag_id: this.selectedTags
      }, tags = [];
      this.$http.post('/message/action/tag', data).then(response => {
        if(response.data.result) {
          this.bus.$emit('tagApplied', response.data.data)
          this.toggle.tagsModal = false;
        } else {
          console.error("Failed to update tags");
        }
          this.selectedTags.map( tag => {
              let tagIndex = this.session.tags.findIndex( x => x.id === tag);
              tags.push(this.session.tags[tagIndex].name)
          });
        this.MixPanelTrack("Tag Applied", {
            "Tag": tags,
            "Organization": this.Organization(this.session.organization_phone),
            "$name": this.session.name
        })
      });
    },
  }
}
</script>
<style>
body{
  color: #343434 !important;
}
.user-panel {
  height: 100vh !important;
}
</style>
