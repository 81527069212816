<template>
    <div class="reply-to">
        <message-types :message="message" :message-layout="messageLayout"></message-types>
    </div>
</template>

<script>
import MessageTypes from "./MessageTypes";
export default {
    name: "RepliedTo",
    components: {MessageTypes},
    props: ["message","messageLayout"],
}
</script>

<style scoped>
.reply-to {
    background: #ccc;
    color: #343434;
    border-left: 6px solid #343434;
    border-radius: 24px;
    cursor: pointer;
    overflow: hidden;
}

.reply-to .chat-media {
    padding: 0;
}
</style>