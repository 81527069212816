import Vue from 'vue'
import App from './App.vue'
import {BootstrapVue, IconsPlugin, ToastPlugin} from "bootstrap-vue"
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import './assets/css/app.css'
//import './assets/css/simplelineicons/css/simple-line-icons.css'
//import './assets/css/font-awesome/css/font-awesome.min.css'
import './assets/css/style.css'
import axios from "axios";
import moment from "moment"
//import Toast from 'vue-easy-toast'
import infiniteScroll from 'vue-infinite-scroll'
import AudioRecorder from 'audio-recorder-vue'
import router from './router'
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import vuelidateErrorExtractor  from "vuelidate-error-extractor";
import mixpanel from 'mixpanel-browser';
import vueDebounce from 'vue-debounce'

//import VueMixpanel from 'vue-mixpanel'

Vue.use(AudioRecorder)
Vue.use(infiniteScroll)
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(ToastPlugin)
Vue.use(vueDebounce)


Vue.prototype.$http = axios;
//Vue.prototype.$toast = Toast;
Vue.mixin({
  methods: {
    toast: (message, toastType = 'info') => {
      let variant = 'info';
      if(toastType === 'error') {
        variant = 'danger';
      } else if (toastType === 'success') {
        variant = 'success';
      }
      this.$bvToast.toast(message, {
        variant: variant
      })
    },
    errorHandler: error => {
      Sentry.captureException(error)
      console.error("Error::", error);
    },
    MixPanelTrack: (event, properties = {}) => {
      mixpanel.track(event, properties)
    },
    Organization: phone => {
      let organization = '';
      switch (parseInt(phone)) {
        case 966551740745:
          organization = "Influencer";
          break;
        case 971559729477:
          organization = "CPX";
          break;
      }
      return organization;
    }
  },
})
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL
axios.defaults.headers['Content-Type'] = 'application/json'
if (localStorage.getItem("session")  !== null) {
  let session = JSON.parse(localStorage.getItem('session'));
  axios.defaults.headers['Authorization'] = "Bearer " + session.user_token;
}

/*axios.post('/contact/list').then(response => {
  if(response.data.result) {
    window.contactList = response.data.data;
  }
});*/


Vue.filter('formatDate', function (value){
  if(value) {
    return moment(String(value)).fromNow()
  }
})

Vue.filter('scheduleDate', function (value){
  if(value) {
    return moment.utc(value).local().format("Y-MM-DD h:mm A")
  }
})

Vue.filter('lastMessageTime', function (value){
  if(value) {
    return moment.utc(value).local().fromNow()
  }
})

Vue.filter('chatLocalDate', function (value){
  if(value) {
    return moment.utc(value).local().format('h:mm A')
  }
})

Vue.filter('initials', function (name) {
  var names = name.split(' '),
      initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
})

Vue.filter('formatMessageDate', function (value){
  if(value) {
    //console.info(Intl.DateTimeFormat().resolvedOptions().timeZone);
    return moment().tz('Asia/Calcutta').utc(String(value)).format('h:mm A')
    //MMM D,
  }
})

Vue.config.productionTip = false

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["web.cheramiapp.com", /^\//],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

mixpanel.init(process.env.VUE_APP_MIXPANEL_TOKEN, {
  debug: parseInt(process.env.VUE_APP_MIXPANEL_DEBUG)
});

Vue.use(vuelidateErrorExtractor, {
  i18n: false,
  // Define common validation messages.
  messages: {
    required: "{attribute} is required!",
    isJoe: "{attribute} must be Joe",
    notGmail: "{attribute} must not be gmail",
    email: "{attribute} is not a valid Email address.",
    isEmailAvailable:
        "{attribute} is not available. Must be at least 10 characters long."
  }
});

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
