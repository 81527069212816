<template>
  <div id="app">
    <header class="nav-head">
      <b-navbar toggleable="lg" type="light" class="menubar">
        <b-navbar-brand to="/"><img width="27" src="@/assets/images/logo-transperant.png" /></b-navbar-brand>
        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav class="ml-auto">
            <b-nav-item to="/" right>{{this.Organization(session.organization_phone)}} Home</b-nav-item>
            <b-nav-item to="/manager" v-if="session.role === 'Manager'" right>Manager</b-nav-item>
            <b-nav-item to="/conversation/assignments" v-if="session.role === 'Manager'" right>Assignment Logs</b-nav-item>
            <b-nav-item to="/user-templates" right>Templates</b-nav-item>
            <notifications-dropdown :bus="bus"/>
            <b-nav-item right v-b-toggle.sidebar-no-header><b-avatar size="sm"></b-avatar> <em>{{ session.name }}</em></b-nav-item>
            <b-nav-item @click="logout" right><strong><b-icon-power/></strong></b-nav-item>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </header>
    <router-view :bus="bus"/>
    <sidebar-component :bus="bus"/>
  </div>
</template>

<script>
import router from "./router";
import SidebarComponent from "./components/SidebarComponent";
import NotificationsDropdown from "./components/NotificationsDropdown";
import Vue from "vue";

export default {
  components: {NotificationsDropdown, SidebarComponent},
  data() {
    return {
      bus: new Vue(),
      session: {},
    }
  },
  mounted() {
    if (localStorage.getItem("session")  !== null) {
      this.session = JSON.parse(localStorage.getItem('session'))
    }
  },
  methods: {
    logout: function () {
      localStorage.removeItem('session');
      this.session = {};
      router.push('/');
      location.reload();
    }
  }
}
</script>
<style>
.sub-title {
  font-size: 60%;
}
.cherami {
  color: #343434;
  font-weight: bold;
  opacity: 0.9;
  /*text-shadow: 1px 1px 3px #32e188;*/
}
</style>
