<template>
  <article>
    <section>
      <header class="panel-header c-panel-header">
        <div class="title">
          <h1>Conversations <leave-all-takeovers /></h1>
          <span id="filter-representation">
            <!--              <select @change="filterData" v-model="dataFilters.availableFor">
                  <option value="me">Me</option>
                  <option value="available">Available</option>
                  <option value="all">All</option>
              </select>-->
            <agent-filter
              v-if="session.role !== 'Freelancer'"
              @availibilityFilter="filterByAvailibility"
            />
          </span>
        </div>
        <a
          href="javascript: void(0);"
          @click="bus.$emit('openContacts', true)"
          class="chat-edit btn-conv-init"
          ><b-icon-pencil-square
        /></a>
      </header>
      <div class="search-filt-bar">
        <div class="search-grey-bx">
          <form @submit.prevent>
            <input
              type="text"
              v-model="dataFilters.keyword"
              value=""
              class="search-field"
              v-debounce:400ms="filterData"
              placeholder="Type keyword and press enter"
            />
            <a
              v-if="dataFilters.keyword === ''"
              href="javascript:void(0);"
              class="conv-icon"
              :class="session.role !== 'Freelancer' ? '' : 'conv-search-icon'"
              ><b-icon-search
            /></a>
            <a
              v-if="dataFilters.keyword !== ''"
              @click="
                dataFilters.keyword = '';
                filterData();
              "
              href="javascript:void(0);"
              class="conv-icon"
              ><b-icon-x-circle
            /></a>
            <a
              v-if="session.role !== 'Freelancer'"
              href="javascript:void(0);"
              id="popover-button-sync"
              @click="toggle.filters = true"
              class="icon btn-filter"
            ></a>
            <b-badge
              class="filter-badge"
              v-if="
                dataFilters.labels.length > 0 || dataFilters.tags.length > 0
              "
              variant="info"
              >{{
                dataFilters.labels.length + dataFilters.tags.length
              }}</b-badge
            >
            <div class="d-flex flex-column text-md-center">
              <div class="p-2">
                <b-popover
                  :show.sync="toggle.filters"
                  target="popover-button-sync"
                  title="Filter by Labels"
                >
                  <form @submit.prevent="filterData">
                    <div class="user-tag-ct" id="filterLabels11">
                      <ul>
                        <li
                          v-for="(label, lable_id) in session.labels"
                          :key="lable_id"
                        >
                          <label class="tag-name">
                            <input
                              v-model="dataFilters.labels"
                              v-bind:value="label.id"
                              type="checkbox"
                            />
                            <span class="checkmark"
                              ><div
                                :style="{ background: label.colour }"
                                class="label-colour-dot"
                              ></div>
                              {{ label.name }}</span
                            >
                          </label>
                        </li>
                      </ul>
                      <h3 class="popover-header c-popover-header">
                        Filter by Tags
                      </h3>
                      <ul>
                        <li v-for="(tag, tag_id) in session.tags" :key="tag_id">
                          <label class="tag-name">
                            <input
                              v-model="dataFilters.tags"
                              v-bind:value="tag.id"
                              type="checkbox"
                            />
                            <span class="checkmark"
                              ><div
                                :style="{ background: tag.colour }"
                                class="label-colour-dot"
                              ></div>
                              {{ tag.name }}</span
                            >
                          </label>
                        </li>
                      </ul>
                    </div>
                    <b-button
                      :style="{ width: '100%' }"
                      type="submit"
                      variant="primary"
                      >Filter</b-button
                    >
                  </form>
                </b-popover>
              </div>
            </div>
          </form>
        </div>
      </div>
      <b-overlay :show="overlay">
        <div
          class="user-chat-lists conv-list"
          v-infinite-scroll="fetch"
          infinite-scroll-disabled="busy"
          infinite-scroll-distance="10"
        >
          <div v-if="this.conversations.length === 0" class="text-center">
            <p>No Conversations</p>
          </div>
          <ul id="conversation-list" class="chat-list">
            <li
              class="show"
              :class="
                activeConversation.id === conversation.id ? 'conv-active' : ''
              "
              v-for="conversation in conversations"
              :key="conversation.id"
            >
              <div
                class="chat-item conversation-chat-item"
                @click="openConversation(conversation)"
              >
                <div class="dropdown chat-avatar">
                  <a class="avatar chat-avt" href="javascript:void(0);">
                    <img
                      class="profile-image"
                      width="46"
                      v-bind:src="
                        conversation.influencer != null
                          ? conversation.influencer.display_picture
                          : 'https://cherami-media.s3.ap-southeast-1.amazonaws.com/images/profile-placeholder.jpg'
                      "
                    />
                  </a>
                  <a
                    v-if="
                      conversation.user != null && session.role == 'Manager'
                    "
                    href="javascript:void(0)"
                    ><b-avatar
                      variant="secondary"
                      size="1.5rem"
                      :text="
                        (conversation.user ? conversation.user.name : '')
                          | initials
                      "
                      class="mr-4 avatar-marker"
                    ></b-avatar
                  ></a>
                </div>
                <div class="chat-user-ct">
                  <span
                    v-if="conversation.labels.length > 0"
                    class="selected-labels"
                  >
                    <!--                    <i v-for="label in conversation.labels" v-bind:data-label-id="label.label_id" :key="label.id" class="circle" v-bind:style="{ 'background-color': session.labels[label.label_id].colour}"></i>-->
                    <i
                      v-for="label in conversation.labels"
                      v-bind:data-label-id="label.label_id"
                      :key="label.id"
                      :style="{ backgroundColor: label.colour }"
                      class="circle"
                    ></i>
                  </span>
                  <span class="chat-user-name"
                    >{{
                      conversation.influencer
                        ? conversation.influencer.name != ""
                          ? conversation.influencer.name
                          : conversation.influencer.phone
                        : ""
                    }}
                    <b-badge v-if="conversation.admin_tookover"
                      >Tookover</b-badge
                    ></span
                  >
                  <p class="chat-item-last">
                    <b-icon
                      v-if="conversation.icon"
                      :icon="conversation.icon"
                    ></b-icon>
                    {{
                      conversation.last_message !== null
                        ? conversation.last_message.substring(0, 40)
                        : ""
                    }}
                  </p>
                  <time class="chat-time">{{
                    conversation.last_message_time | lastMessageTime
                  }}</time>
                  <div class="li-user-ri">
                    <i
                      v-if="conversation.is_pinned === 1"
                      class="us-act icon icon-pushpin"
                    ></i>
                    <i
                      v-if="conversation.is_read === 0"
                      class="us-act online"
                    ></i>
                    <div class="dropdown user-li-drop">
                      <b-dropdown dropright>
                        <template #button-content>
                          <b-icon-three-dots-vertical />
                        </template>
                        <b-dropdown-item
                          v-if="conversation.is_pinned === 1"
                          @click="conversationAction(conversation.id, 'pin', 0)"
                          >Unpin</b-dropdown-item
                        >
                        <b-dropdown-item
                          v-if="conversation.is_pinned === 0"
                          @click="conversationAction(conversation.id, 'pin', 1)"
                          >Pin</b-dropdown-item
                        >
                        <b-dropdown-item
                          @click="conversationLabels(conversation.id)"
                          >Label</b-dropdown-item
                        >
                        <b-dropdown-item
                          @click="
                            deleteConversation(conversation.id, 'archieve', 1)
                          "
                          >Archive</b-dropdown-item
                        >
                        <b-dropdown-item
                          @click="
                            conversationAction(conversation.id, 'read', 1)
                          "
                          >Mark as Unread</b-dropdown-item
                        >
                        <b-dropdown-item
                          v-if="conversation.user_id === session.id"
                          @click="
                            conversationRemoveMe(conversation.id, 'assign', 0)
                          "
                          >Remove from me</b-dropdown-item
                        >
                        <b-dropdown-item
                          class="contact-info-item"
                          v-b-toggle.contact-info
                          >Contact Info</b-dropdown-item
                        >
                        <b-dropdown-item
                          @click="
                            deleteConversation(conversation.id, 'archieve', 1)
                          "
                          >Delete</b-dropdown-item
                        >
                      </b-dropdown>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </b-overlay>

      <message-forward
        :bus="bus"
        :messageId="forwardMessageId"
        v-if="forwardMessageId !== null"
      ></message-forward>
    </section>

    <!--    <section class="side-panel filter-panel show" v-show="toggle.filters">
      <form @submit.prevent="filterData">
        <div class="panel-header">
          <a href="javascript:void(0);" @click="toggle.filters = false" class="icon icon-back icon-back-filter"></a>
          <h1>Filter By</h1>
        </div>
        <div class="filter-cont">
          <h6>Availability</h6>
          <div id="filterAvl" class="filter-availability mb-3">
            <b-form-radio-group
                id="btn-radios-3"
                v-model="dataFilters.availableFor"
                :options="dataFilters.availableForOptions"
                name="radio-btn-stacked"
                buttons
                stacked
            ></b-form-radio-group>
          </div>
          <h6 class="mb-3">Labels</h6>
          <div class="user-tag-ct" id="filterLabels11">
            <ul>
              <li v-for="(label, lable_id) in session.labels" :key="lable_id">
                <label class="tag-name">
                  <input v-model="dataFilters.labels" v-bind:value="label.id" type="checkbox"/>
                  <span class="checkmark"><div :style="{'background': label.colour}" class="label-colour-dot"></div>{{ label.name }}</span>
                </label>
              </li>
            </ul>
          </div>
          <b-button :style="{ width: '100%' }" type="submit" variant="primary">Filter</b-button>
        </div>
      </form>
    </section>-->
    <b-modal v-model="toggle.labelsModal" hide-footer title="Labels">
      <form @submit.prevent="conversationLabelsSave" class="user-tag-ct show">
        <ul>
          <li v-for="label in session.labels" :key="label.id">
            <label class="tag-name">
              <input
                :value="label.id"
                type="checkbox"
                v-model="toggle.activeSelectedLabels"
              />
              <span class="checkmark"
                ><div
                  :style="{ background: label.colour }"
                  class="label-colour-dot"
                ></div>
                {{ label.name }}</span
              >
            </label>
          </li>
        </ul>
        <button class="btn btn-primary btn-apply apply-tags">Apply</button>
      </form>
    </b-modal>
  </article>
</template>

<script>
//import InfiniteLoading from "vue-infinite-loading";
//import Vue from "vue";
import Pusher from "pusher-js";
import moment from "moment";
import MessageForward from "./MessageForward";
import LeaveAllTakeovers from "./LeaveAllTakeovers";
import AgentFilter from "./Filters/AgentFilter";

export default {
  name: "ConversationComponent",
  props: ["bus"],
  components: { AgentFilter, LeaveAllTakeovers, MessageForward },
  data() {
    return {
      session: {},
      dataFilters: {
        title: "Assigned To Me",
        availableFor: "me",
        availableForOptions: [
          { text: "Me", value: "me", selected: true },
          { text: "Available", value: "available" },
          { text: "All", value: "all" },
        ],
        keyword: "",
        labels: [],
        tags: [],
      },
      loadedConversations: [],
      toggle: {
        filters: false,
        threePanel: false,
        activeLabelsDropdown: "",
        activeSelectedLabels: [],
        labelsModal: false,
      },
      refreshChat: true,
      forwardMessageId: null,
      pusherMessage: {},
      activeConversation: {},
      conversations: [],
      conversationsPinned: [],
      conversationsUnpinned: [],
      busy: false,
      endOfScroll: false,
      scrollOffset: -30,
      scrollLimit: 30,
      overlay: true,
      selectedLabelConversationId: null,
    };
  },
  mounted() {
    if (localStorage.getItem("session") !== null) {
      this.session = JSON.parse(localStorage.getItem("session"));
    }
    this.bus.$on("conversationReload", () => {
      this.reloadConversations();
    });
    this.bus.$on("conversationAdopted", (conversation) => {
      this.dataFilters.availableFor = "me";
      this.filterData();
      this.openConversation(conversation);
    });
    this.bus.$on("openConversation", (conversation) => {
      this.openConversation(conversation);
    });
    this.bus.$on("conversationRequestAccepted", (notification) => {
      let conversationIndex = this.conversations.findIndex(
        (x) => x.id === notification.conversation_id
      );
      let conversation = this.conversations[conversationIndex];
      this.MixPanelTrack("Conversation Request Accepted", {
        "Contact Name": conversation.influencer.name,
        $name: this.session.name,
        Organization: this.Organization(this.session.organization_phone),
        "Assigned User": notification.requested_to.name,
        "Requested User": notification.requested_by.name,
      });
      this.reloadConversations();
    });
    this.bus.$on("conversationMessageAccepted", (messageReq) => {
      let conversationIndex = this.conversations.findIndex(
        (x) => x.id === messageReq.conversation_id
      );
      let conversation = this.conversations[conversationIndex];
      conversation.icon = messageReq.icon;
      conversation.last_message = messageReq.last_message;
      this.sortedConversations();
    });
    this.bus.$on("takeover", () => {
      this.takeover();
    });
    this.bus.$on("forwardMessage", (messageId) => {
      this.forwardMessageId = messageId;
    });
    this.bus.$on("reloadConversations", () => {
      this.filterData();
    });
    this.bus.$on("refreshAfterForward", (data) => {
      data.conversationIds.forEach((conversation) => {
        // let conversationIndex = this.conversations.findIndex(
        //   (x) => x.id === conversation.conversationId
        // );
        // this.conversations[conversationIndex].last_message = data.message.body;
        // this.conversations[conversationIndex].last_message_time =
        //   data.message.created_at;
        this.conversationLastMessageType(data.message, conversation);
        if (this.activeConversation.id === conversation.conversationId) {
          data.message.id = conversation.messageId;
          data.message.is_sent = 1;
          data.message.created_at = moment.now();
          this.bus.$emit("newPusher", data.message);
        }
        this.forwardMessageId = null;
        this.sortedConversations();
      });
    });
    Pusher.logToConsole = parseInt(process.env.VUE_APP_PUSHER_LOGS);
    let pusher = new Pusher(process.env.VUE_APP_PUSHER_APP_KEY, {
        cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
      }),
      channel = pusher.subscribe(this.session.organization_phone.toString());
    channel.bind("message", (data) => {
      let conversationIndex = this.conversations.findIndex(
        (x) => x.id === data.conversation_id
      );
      if (
        typeof this.conversations[conversationIndex] !== undefined &&
        conversationIndex >= 0
      ) {
        try {
          let lastReceivedMessage = {
            created_at: data.created_at,
            conversation_id: data.conversation_id,
          };
          this.conversations[conversationIndex].last_message = data.body;
          this.conversations[conversationIndex].last_message_time =
            data.created_at;
          this.conversations[conversationIndex].last_received_message =
            lastReceivedMessage;

          // if (data.message_type === "media") {
          //   this.conversations[conversationIndex].last_message = "Attachment";
          // }
          // if (data.message_type === "location") {
          //   this.conversations[conversationIndex].last_message = "Location";
          // }
          if (data.message_type === "location") {
            this.conversations[conversationIndex].icon = `geo-alt-fill`;
            this.conversations[conversationIndex].last_message = "Location";
          } else if (
            this.conversations[
              conversationIndex
            ].last_message_media_content_type.substring(0, 5) == "video"
          ) {
            this.conversations[conversationIndex].icon = `camera-video-fill`;
            this.conversations[conversationIndex].last_message = "Video";
          } else if (
            this.conversations[
              conversationIndex
            ].last_message_media_content_type.substring(0, 5) == "audio"
          ) {
            this.conversations[conversationIndex].icon = `music-note-beamed`;
            this.conversations[conversationIndex].last_message = "Audio";
          } else if (
            this.conversations[
              conversationIndex
            ].last_message_media_content_type.substring(0, 5) == "image"
          ) {
            this.conversations[conversationIndex].icon = `camera-fill`;
            this.conversations[conversationIndex].last_message = "Photo";
          } else if (
            this.conversations[conversationIndex]
              .last_message_media_content_type != ""
          ) {
            this.conversations[conversationIndex].icon = `file-text-fill`;
            this.conversations[conversationIndex].last_message = "Document";
          }
          //this.conversations[conversationIndex] = this.activeConversation;
          this.openConversation(this.activeConversation);
          if (this.activeConversation.id === data.conversation_id) {
            this.activeConversation.last_received_message = lastReceivedMessage;
            this.bus.$emit("newPusher", data);
          } else {
            this.conversations[conversationIndex].is_read = 0;
          }
          this.sortedConversations();
          this.MixPanelTrack("Message Received", {
            "Contact Name": data.influencer.name,
            Organization: this.Organization(this.activeConversation.phone_home),
            $name: this.session.name,
            "Reply?": data.msg_id == null ? false : true,
            "Message Type": data.message_type,
            "Response Text": data.body,
          });
        } catch (err) {
          console.warn(err);
          console.warn(conversationIndex);
          this.bus.$emit("logout", true);
        }
      }
    });
    this.fetch();
  },
  methods: {
    conversationLastMessageType(data, Ids) {
      let conversationIndex = this.conversations.findIndex(
        (x) => x.id === Ids.conversationId
      );
      let conversation = this.conversations[conversationIndex];
      this.conversations[conversationIndex].is_read = 0;
      if (data.message_type === "text") {
        conversation.icon = ``;
        conversation.last_message = data.body;
        conversation.last_message_time = data.created_at;
      } else if (data.message_type === "location") {
        conversation.icon = `geo-alt-fill`;
        conversation.last_message = "Location";
      } else if (data.media_content_type.substring(0, 5) == "video") {
        conversation.icon = `camera-video-fill`;
        conversation.last_message = "Video";
      } else if (data.media_content_type.substring(0, 5) == "audio") {
        conversation.icon = `music-note-beamed`;
        conversation.last_message = "Audio";
      } else if (data.media_content_type.substring(0, 5) == "image") {
        conversation.icon = `camera-fill`;
        conversation.last_message = "Photo";
      } else if (data.media_content_type != "") {
        conversation.icon = `file-text-fill`;
        conversation.last_message = "Document";
      }
    },
    sortedConversations() {
      this.conversationsPinned = this.conversations.filter(
        (item) => item.is_pinned == 1
      );
      this.conversationsUnpinned = this.conversations.filter(
        (item) => item.is_pinned == 0
      );
      function compare(a, b) {
        if (new Date(a.last_message_time) > new Date(b.last_message_time))
          return -1;
        if (new Date(a.last_message_time) < new Date(b.last_message_time))
          return 1;
        return 0;
      }
      this.conversationsPinned.sort(compare);
      this.conversationsUnpinned.sort(compare);
      this.conversations = this.conversationsPinned.concat(
        this.conversationsUnpinned
      );
    },
    filterByAvailibility(agentId) {
      this.dataFilters.availableFor = agentId;
      this.filterData();
    },
    reloadConversations() {
      this.filterData();
    },
    takeover() {
      window.location.reload();
      this.filterData();
      this.openConversation(this.activeConversation);
    },
    fetch() {
      this.scrollOffset = this.scrollOffset + this.scrollLimit;
      if (this.endOfScroll === false) {
        this.overlay = true;
        this.$http
          .post(`/web/conversation/list`, {
            filters: {
              labels: this.dataFilters.labels,
              tags: this.dataFilters.tags,
              availableFor: this.dataFilters.availableFor,
              keyword: this.dataFilters.keyword,
            },
            pagination: {
              offset: this.scrollOffset,
              limit: this.scrollLimit,
            },
          })
          .then((response) => {
            if (this.dataFilters.keyword !== "") {
              this.MixPanelTrack("Search - Conversations", {
                "Search Term": this.dataFilters.keyword,
                "# of Results Returned": response.data.data.length,
                "Search Character Length": this.dataFilters.keyword.length,
              });
            }
            this.overlay = false;
            if (response.data.data.length === 0) {
              this.endOfScroll = true;
            } else {
              if (response.data) {
                for (let i = 0; i < response.data.data.length; i++) {
                  if (response.data.data[i].labels.length > 0) {
                    for (
                      let j = 0;
                      j < response.data.data[i].labels.length;
                      j++
                    ) {
                      let labelIndex = this.session.labels.findIndex(
                        (x) => x.id === response.data.data[i].labels[j].label_id
                      );
                      if (labelIndex >= 0) {
                        response.data.data[i].labels[j]["colour"] =
                          this.session.labels[labelIndex].colour;
                      }
                    }
                  }
                  if (response.data.data[i].last_message_media_content_type)
                    if (
                      response.data.data[i].last_message_type === "location"
                    ) {
                      response.data.data[i].icon = `geo-alt-fill`;
                      response.data.data[i].last_message = "Location";
                    } else if (
                      response.data.data[
                        i
                      ].last_message_media_content_type.substring(0, 5) ==
                      "video"
                    ) {
                      response.data.data[i].icon = `camera-video-fill`;
                      response.data.data[i].last_message = "Video";
                    } else if (
                      response.data.data[
                        i
                      ].last_message_media_content_type.substring(0, 5) ==
                      "audio"
                    ) {
                      response.data.data[i].icon = `music-note-beamed`;
                      response.data.data[i].last_message = "Audio";
                    } else if (
                      response.data.data[
                        i
                      ].last_message_media_content_type.substring(0, 5) ==
                      "image"
                    ) {
                      response.data.data[i].icon = `camera-fill`;
                      response.data.data[i].last_message = "Photo";
                    } else if (
                      response.data.data[i].last_message_media_content_type !=
                      ""
                    ) {
                      response.data.data[i].icon = `file-text-fill`;
                      response.data.data[i].last_message = "Document";
                    }
                  this.conversations.push(response.data.data[i]);
                }
              }
            }
            this.sortedConversations();
            this.busy = false;
          })
          .catch((err) => {
            console.warn("Error on conversation fetch");
            console.log(err);
            this.errorHandler(err);
          });
      }
    },
    openConversation(conversation) {
      if (conversation.user_id === this.session.id) {
        let lastMessageTime = moment.utc("1970-01-01 00:00:00").format();
        if (conversation.last_received_message != null) {
          lastMessageTime = moment
            .utc(conversation.last_received_message.created_at)
            .format();
          //console.info(`Last received message: ${conversation.last_received_message.created_at}`);
        }
        if (moment.utc().diff(moment(lastMessageTime), "minutes") >= 1440) {
          conversation.messageFormType = "templateForm";
        } else {
          conversation.messageFormType = "textForm";
        }

        /**
         * Marking conversation as read
         */
        if (conversation.is_read === 0) {
          this.$http
            .post("/conversation/action/read", {
              conversation_id: conversation.id,
              value: 1,
            })
            .then((response) => {
              if (!response.data.result) {
                console.info("Error marking conversation as read");
              }
            })
            .catch((err) => {
              console.log(err);
              this.errorHandler(err);
            });
          this.MixPanelTrack("Conversation Opened", {
            "Contact Name": conversation.influencer.name,
            Status: "Active",
            "Last Message Received Time": conversation.last_message_time,
            "Conversation Label": labels,
            $name: this.session.name,
            Organization: this.Organization(this.session.organization_phone),
            "Assigned User": conversation.user.name,
          });
        }

        /**
         * Mix panel event
         * @type {*[]}
         */
        let labels = [];
        for (let i = 0; i < conversation.labels.length; i++) {
          let labelIndex = this.session.labels.findIndex(
            (x) => x.id === conversation.labels[i].label_id
          );
          labels.push(this.session.labels[labelIndex].name);
        }
      } else {
        if (conversation.user_id == null) {
          conversation.messageFormType = "available";
        } else {
          conversation.messageFormType = "assigned";
        }
      }
      this.activeConversation = conversation;
      this.activeConversation.is_read = 1;
      this.bus.$emit("activeConversation", conversation);
    },
    filterData() {
      this.scrollOffset = -30;
      this.scrollLimit = 30;
      this.busy = false;
      this.endOfScroll = false;
      this.conversations = [];
      this.loadedConversations = [];
      this.toggle.filters = false;
      if (this.dataFilters.availableFor === "all") {
        this.dataFilters.title = "All";
      } else if (this.dataFilters.availableFor === "available") {
        this.dataFilters.title = "Available";
      } else {
        this.dataFilters.title = "Assigned To Me";
      }
      this.fetch();
    },
    deleteConversation(conversationId, action, value) {
      this.$bvModal
        .msgBoxConfirm("Are you sure, you want to delete this conversation?")
        .then((v) => {
          if (v) {
            this.conversationAction(conversationId, action, value);
          }
        })
        .catch((err) => {
          // An error occurred
          console.error(err);
          this.errorHandler(err);
        });
      //
    },
    conversationRemoveMe(conversationId, action, value) {
      this.$bvModal
        .msgBoxConfirm("Are you sure, you want to un-assign this conversation?")
        .then((v) => {
          if (v) {
            this.conversationAction(conversationId, action, value);
          }
        })
        .catch((err) => {
          // An error occurred
          console.error(err);
          this.errorHandler(err);
        });
      //
    },
    conversationAction(conversationId, action, value) {
      this.$http
        .post(`conversation/action/${action}`, {
          conversation_id: conversationId,
          value: value,
        })
        .then((response) => {
          if (response.data.result) {
            this.filterData();
          } else {
            console.log("Pin Conversation Error");
          }
        });
    },
    conversationLabels(conversationId) {
      //this.toggle.activeLabelsDropdown = conversationId;
      this.selectedLabelConversationId = conversationId;
      this.toggle.labelsModal = true;
      this.toggle.activeSelectedLabels = [];
      let conversationIndex = this.conversations.findIndex(
        (x) => x.id === conversationId
      );
      for (
        var i = 0;
        i < this.conversations[conversationIndex].labels.length;
        i++
      ) {
        this.toggle.activeSelectedLabels.push(
          this.conversations[conversationIndex].labels[i].label_id
        );
      }
    },
    conversationLabelsSave() {
      this.$http
        .post("/conversation/action/label", {
          conversation_id: this.selectedLabelConversationId,
          label_id: this.toggle.activeSelectedLabels,
        })
        .then((response) => {
          if (response.data.result) {
            this.toggle.labelsModal = false;
            var conversationIndex = this.conversations.findIndex(
              (x) => x.id === this.selectedLabelConversationId
            );
            this.conversations[conversationIndex].labels = [];
            for (var i = 0; i < this.toggle.activeSelectedLabels.length; i++) {
              var labelIndex = this.session.labels.findIndex(
                (x) => x.id === this.toggle.activeSelectedLabels[i]
              );
              this.conversations[conversationIndex].labels.push({
                label_id: this.toggle.activeSelectedLabels[i],
                conversation_id: this.selectedLabelConversationId,
                colour: this.session.labels[labelIndex].colour,
              });
            }
            this.$bvToast.toast(`Label updated successfully`, {
              variant: "success",
              title: "Success",
            });
          } else {
            this.$bvToast.toast(`Label could not be updated`, {
              variant: "danger",
              title: "Error",
            });
          }
        });
    },
  },
};
</script>

<style scoped>
.filter-badge {
  position: absolute;
  top: -10px;
  right: -10px;
  padding: 5px 7px;
  border-radius: 10px;
  font-size: 13px;
}
</style>
