<template>
    <article>
        <div class="modal-backdrop"></div>
        <div class="modal fade show d-block" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <b-overlay :show="overlay">
                    <div class="modal-content">
                        <b-form @submit.stop.prevent="submit">
                            <div class="modal-body login-box">
                                <img class="img-responsive" src="@/assets/images/logo.png" width="184">
                                <h2 class="mb-2">Log in</h2>
                                <p>Welcome back</p>
                            </div>
                            <div class="login-form-container">
                                <b-form-group>
                                    <b-form-input v-model="form.email" @focus="checkEmailField('email')" @keyup="checkEmailField('email')" :class="validations.email"></b-form-input>
                                    <b-form-invalid-feedback>This is a required field and must be a valid email address.</b-form-invalid-feedback>
                                </b-form-group>
                                <b-form-group>
                                    <b-form-input type="password" v-model="form.password" @focus="checkTextField('password')" @keyup="checkTextField('password')" :class="validations.password"></b-form-input>
                                    <b-form-invalid-feedback id="input-2-live-feedback">This is a required field.</b-form-invalid-feedback>
                                </b-form-group>
                                <b-alert v-if="errorMessage !== ''" show variant="danger">{{ errorMessage }}</b-alert>
                                <b-row>
                                    <b-col class="text-center">
                                        <b-button type="submit" class="sign-in-button" variant="primary">Sign In</b-button>
                                    </b-col>
                                </b-row>
                            </div>
                        </b-form>
                    </div>
                </b-overlay>
            </div>
        </div>
    </article>
</template>

<script>
/*
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
*/

export default {
    //mixins: [validationMixin],
    name: 'LoginComponent',
    props: ['bus'],
    //inject: ['mixpanel'],
    data() {
        return {
            form: {
                email: '',
                password: '',
                device_token: 'WEB'
            },
            validations: {
                email: '',
                password: ''
            },
            overlay: false,
            isValid: null,
            errorMessage: '',
            serverErrors: {}
        }
    },
    /*validations: {
        form: {
            email: {
                required,
                email
            },
            password: {
                required
            },
        }
    },*/
    mounted() {
        //console.info(process.env.BASE_URL);
    },
    methods: {
        resetForm() {
            this.form = {
                email: null,
                password: null,
                is_web: true
            };
        },
        checkTextField(field) {
            if ( this.form[field] !== '' ) {
                this.validations[field] = 'is-valid';
            } else {
                this.validations[field] = 'is-invalid';
            }
        },
        checkEmailField(field) {
            const re = /\S+@\S+\.\S+/;
            if ( this.form[field] !== '' ) {
                if (re.test(this.form[field]) ) {
                    this.validations[field] = 'is-valid';
                } else {
                    this.validations[field] = 'is-invalid';
                }
            } else {
                this.validations[field] = 'is-invalid';
            }
        },
        /*hasServerError(field) {
            return (field && _.has(this, 'serverErrors.' + field) && !_.isEmpty(this.serverErrors[field]));
        },*/
        submit: function () {
            //this.mixpanel.track('Login');
            this.errorMessage = '';
            this.overlay = true
            this.$http.post(`/auth/login`, this.form).then(response => {
                if (response.data.result) {
                    this.MixPanelTrack('Login', {
                        "# of Devices":"",
                        "Platforms Used":"Web",
                        "Stay signed in?": false,
                        "User Type": response.data.data.user.role,
                        "Organization": this.Organization(response.data.data.user.organization_phone),
                        "$email": response.data.data.user.email,
                        "$name": response.data.data.user.name
                    });
                    this.$http.defaults.headers['Authorization'] = "Bearer " + response.data.data.token;
                    this.$http.get(`/auth/me/details`).then(response => {
                        this.overlay = false;
                        if (response.data.result) {
                            this.bus.$emit('reloadConversations')
                            localStorage.setItem('session', JSON.stringify(response.data.data));
                            this.bus.$emit('setSession', response.data.data);
                            window.location.reload();
                        }
                    }).catch(error => {
                        this.overlay = false;
                        this.isValid = false;
                        this.errorHandler(error)
                    })
                } else {
                    this.validations['email'] = 'is-invalid';
                    this.validations['password'] = 'is-invalid';
                    this.errorMessage = response.data.error.message;
                    this.overlay = false;
                    this.isValid = false;
                }
            }).catch( error => {
                this.overlay = false;
                this.isValid = false;
                this.errorHandler(error)
            })
        }
    }
}
</script>
<style scoped>
.modal-content {
    background: #F8F8F8;
}
.login-form-container {
    margin: 10px;
}
.sign-in-button {
    padding: 8px 30px;
    border-radius: 20px;
}
input.form-control {
    border-radius: 20px;
}
</style>
