<template>
  <section class="side-panel recipent-panel show">
    <div class="panel-header">
      <a
        href="javascript:void(0)"
        @click="bus.$emit('openContacts', false)"
        class="icon icon-back-recipt icon-back"
      ></a>
      <h1>
        Recipients
        <a
          href="javascript:void(0)"
          @click="addContact"
          class="contact-add-button"
          ><b-icon-plus-square
        /></a>
      </h1>
      <a href="javascript:void(0);" class="icon icon-add-recipt"></a>
    </div>
    <div class="search-filt-bar">
      <div class="search-grey-bx">
        <form>
          <input
            type="text"
            v-model="searchKeyword"
            v-debounce:400ms="contactList"
            class="search-field"
            placeholder="Type Contact Name"
          />
          <a
            v-if="searchKeyword === ''"
            href="javascript:void(0);"
            class="conv-icon"
            ><b-icon-search
          /></a>
          <a
            v-if="searchKeyword !== ''"
            @click="clearSearch"
            href="javascript:void(0);"
            class="conv-icon"
            ><b-icon-x-circle
          /></a>
        </form>
      </div>
    </div>
    <b-overlay :show="overlay" rounded="sm">
      <div class="receipt-list">
        <div class="rectp-lit-bl contacts">
          <ul
            ref="contactList"
            class="list-group-scroll"
            style="min-height: 200px"
          >
            <li
              :style="[
                contact.is_available === 1
                  ? { color: 'green', 'font-weight': 'bold' }
                  : '',
              ]"
              v-for="contact in contacts"
              :key="contact.id"
            >
              <div class="avatar" @click="conversationInit(contact)">
                <img width="25" v-bind:src="contact.display_picture" />
              </div>
              <span class="rect-username" @click="conversationInit(contact)">{{
                contact.name === "" ? contact.phone : contact.name
              }}</span
              >&nbsp;<span class="contact-phone">{{ contact.phone }}</span>
              <a
                href="javascript:void(0)"
                class="contact-edit-button"
                @click="editContact(contact)"
                ><b-icon-pencil-square
              /></a>
            </li>
          </ul>
          <p
            v-if="
              searchKeyword !== '' &&
              $refs.contactList.querySelectorAll('li.show').length === 0
            "
            class="text-center"
          >
            No contacts
          </p>
        </div>
      </div>
    </b-overlay>
    <b-modal v-model="toggle.contactModal" hide-footer title="Contact">
      <contact-form :bus="bus" :contactId="contactId"></contact-form>
    </b-modal>
  </section>
</template>
<script>
// import moment from "moment";
import ContactForm from "./Contacts/ContactForm";
export default {
  name: "ContactsComponent",
  components: { ContactForm },
  props: ["bus", "session"],
  data() {
    return {
      contacts: [],
      contactId: null,
      searchKeyword: "",
      overlay: true,
      toggle: {
        contactModal: false,
      },
      activeConversation: {},
    };
  },
  mounted() {
    this.contactList();
    this.bus.$on("contactsReload", () => {
      this.toggle.contactModal = false;
      this.contactList();
    });
  },
  methods: {
    contactList: function () {
      this.overlay = true;
      this.$http
        .post("/contact/list", {
          keyword: this.searchKeyword,
        })
        .then((response) => {
          if (response.data.result) {
            this.contacts = response.data.data;
          }
          this.overlay = false;
        })
        .catch((error) => {
          this.overlay = false;
          this.errorHandler(error);
        });
    },
    clearSearch: function () {
      console.info("Search clear");
      this.searchKeyword = "";
      this.contactList();
    },
    conversationInit: function (contact) {
      if (confirm("Are you sure, you want start new conversation?")) {
        this.$http
          .post("/conversation/initiate/v2", {
            contact_id: contact.id,
          })
          .then((response) => {
            if (response.data.result) {
              if (response.data.data.already_assigned) {
                let warningMsg = "";
                if (response.data.data.user_id === this.session.id) {
                  this.bus.$emit("openConversation", response.data.data);
                  this.bus.$emit("openContacts", false);
                  this.bus.$emit("reloadConversations", true);
                } else {
                  warningMsg = `Conversation Exist & already assigned to ${response.data.data.user.name}`;
                }
                this.$bvModal.msgBoxOk(warningMsg);
              } else {
                this.bus.$emit("openConversation", response.data.data);
                this.bus.$emit("openContacts", false);
                this.bus.$emit("reloadConversations", true);
                this.contactList();
              }
            }
          })
          .catch((error) => this.errorHandler(error));
      }
    },
    editContact: function (contact) {
      this.contactId = contact.id;
      this.toggle.contactModal = true;
    },
    addContact: function () {
      this.contactId = null;
      this.toggle.contactModal = true;
    },
  },
};
</script>

<style scoped>
.conv-icon {
  right: 12px;
}
.list-group-scroll {
  overflow: scroll;
  max-height: calc(90vh - 210px);
  -webkit-overflow-scrolling: touch;
}
.contact-add-button {
  color: #343434;
  font-size: 25px;
  position: absolute;
  right: 25px;
}
.contact-edit-button {
  color: #343434;
  position: absolute;
  right: 20px;
}
</style>
