<template>
    <div>
        <div v-if="preview">
            <h6 v-html="templateString"></h6>
            <p>&nbsp; <br/> </p>
            <b-button type="button" @click="sendMessage" variant="primary">Send <b-icon-cursor-fill/></b-button>
        </div>
        <b-form v-if="!preview" @submit.stop.prevent="onSubmit">
            <b-form-group
                v-for="(field,i) in this.template.fields"
                :id="`input-group-${field.id}`"
                :label="field.title"
                :label-for="`input-${field.id}`"
                :key="field.id"
            >
                <b-form-input
                    :id="`input-${field.id}`"
                    v-model="template.fields[i].value"
                    :type="fieldTypes[field.type]"
                    :placeholder="field.title"
                    required
                ></b-form-input>
            </b-form-group>
            <b-button type="submit" variant="primary">Preview</b-button>
            &nbsp;<b-button type="reset" @click="onReset" variant="danger">Reset</b-button>
        </b-form>
    </div>
</template>

<script>
export default {
    props: ['template'],
    name: "UserTemplateFields",
    data() {
        return {
            fieldTypes: {
                1: 'text',
                2: 'date',
                3: 'time'
            },
            templateString: this.template.template,
            preview: false
        }
    },
    methods: {
        onSubmit: function () {
            this.template.fields.map(field => {
                this.templateString = this.templateString.replaceAll(field.key, "<b>"+field.value+"</b>");
            })
            this.preview = true
        },
        onReset: function () {
            this.templateString = this.template.template
        },
        sendMessage: function () {
            this.$emit('receiveUserTemplate', this.templateString)
        }
    }
}
</script>

<style scoped>

</style>