<template>
  <b-nav-item-dropdown v-if="notifications.length > 0" right>
    <template #button-content>
      <b-icon-bell-fill/>
    </template>
    <b-dropdown-item v-for="notification in notifications"
                     :key="notification.id"
                     href="#"
                     class="notification-list">
      {{ notification.notification }}
      <b-button size="sm" @click="assignmentAction(notification, 1)" variant="success"><b-icon-check2/></b-button>&nbsp;
      <b-button size="sm" @click="assignmentAction(notification, 0)" variant="danger"><b-icon-x/></b-button>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>
<script>
export default {
  name: "NotificationsDropdown",
  props: ['bus'],
  data() {
    return {
      notifications: []
    }
  },
  mounted() {
    this.assignmentNotifications()
  },
  methods: {
    assignmentNotifications: function () {
      this.$http.get('/conversation/assignment/list/received').then(response => {
        if (response.data.result) {
          this.notifications = response.data.data
        }
      })
    },
    assignmentAction: function (notification, value) {
      this.$bvModal.msgBoxConfirm(`Are you sure, you want to ${value ? 'approve': 'reject'} this request?`)
          .then(v => {
            if(v) {
              this.$http.post('/conversation/assignment/action', {
                id: notification.id,
                accept: value
              }).then(
                response => {
                  if(!response.data.result) {
                    this.toast("Something went wrong", "error")
                  } else {
                    if(value) {
                      this.bus.$emit('conversationRequestAccepted', notification);
                    }
                  }
                });
            }
          })
          .catch(err => {
            // An error occurred
            console.error(err)
          });
    }
  }
}
</script>

<style scoped>
.notification-list {
  font-size: 13px;
}

.notification-list button {
  padding: 3px 10px 0px 10px;
}
</style>