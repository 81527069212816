<template>
  <b-dropdown dropleft no-caret>
    <template #button-content>
      <b-icon-caret-down/>
    </template>
    <b-dropdown-item @click="bus.$emit('tagsModel', message)" class="chat-icon-menu"><b-icon-tags-fill/></b-dropdown-item>
    <b-dropdown-item v-if="message.message_type === 'text' || message.message_type === 'template'" @click="copyToClipboard" class="chat-icon-menu"><b-icon-files/></b-dropdown-item>
    <b-dropdown-item @click="bus.$emit('forwardMessage', message.id)" class="chat-icon-menu"><b-icon-share/></b-dropdown-item>
    <b-dropdown-item @click="deleteMessage" class="chat-icon-menu"><b-icon-trash-fill/></b-dropdown-item>
  </b-dropdown>
</template>
<script>
export default {
    name: "MessageOptions",
    props: ['message', 'bus'],
    data() {
      return {}
    },
    methods: {
        copyToClipboard: function () {
            if(this.message.message_type == 'text' || this.message.message_type == 'template') {
                const el = document.createElement('textarea');
                el.value = this.message.body;
                el.setAttribute('readonly', '');
                el.style.position = 'absolute';
                el.style.left = '-9999px';
                document.body.appendChild(el);
                const selected =  document.getSelection().rangeCount > 0  ? document.getSelection().getRangeAt(0) : false;
                el.select();
                document.execCommand('copy');
                document.body.removeChild(el);
                if (selected) {
                    document.getSelection().removeAllRanges();
                    document.getSelection().addRange(selected);
                }
            } else {
                console.info("Only text and template message can be copied!");
            }

        },
        deleteMessage: function () {
            this.$emit('delete', this.message);
        }
    }
}
</script>

<style scoped>

</style>
