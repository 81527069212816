<template>
  <div
    style="width: 70%"
    v-if="conversation && Object.keys(conversation).length > 0"
    v-on:paste="handlePaste"
  >
    <!-- User chat panel -->
    <main
      class="right-chat-panel"
      :class="session.role === 'Agent' ? 'chat-user-panel' : ''"
    >
      <div id="chat-panel">
        <header class="active-user-panel">
          <div class="chat-header">
            <div class="col-md-4" v-b-toggle.contact-info>
              <div class="conversation-avatar">
                <a href="javascript:void(0)"
                  ><b-avatar
                    variant="secondary"
                    size="3rem"
                    :text="conversation.influencer.name | initials"
                    class="mr-4"
                  ></b-avatar
                ></a>
              </div>
              <div class="chat-header-content">
                <a
                  href="javascript:void(0);"
                  v-if="conversation.influencer.name !== ''"
                  ><h2 class="user-name">
                    {{ conversation.influencer.name }}
                  </h2></a
                >
                <a
                  href="javascript:void(0);"
                  v-if="conversation.influencer.name === ''"
                  ><h2 class="user-name">
                    {{ conversation.influencer.phone }}
                  </h2></a
                >
                <p
                  v-if="conversation.last_message_time != null"
                  class="chat-dt"
                >
                  {{ conversation.last_message_time | lastMessageTime }}
                </p>
              </div>
            </div>
            <div class="col-md-8">
              <div
                v-if="conversation.messageFormType == 'textForm'"
                class="dropdown user-attach pull-right"
              >
                <b-dropdown variant="link">
                  <template #button-content :style="{ color: '#32e188' }"
                    ><svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path
                        fill="currentColor"
                        d="M1.816 15.556v.002c0 1.502.584 2.912 1.646 3.972s2.472 1.647 3.974 1.647a5.58 5.58 0 0 0 3.972-1.645l9.547-9.548c.769-.768 1.147-1.767 1.058-2.817-.079-.968-.548-1.927-1.319-2.698-1.594-1.592-4.068-1.711-5.517-.262l-7.916 7.915c-.881.881-.792 2.25.214 3.261.959.958 2.423 1.053 3.263.215l5.511-5.512c.28-.28.267-.722.053-.936l-.244-.244c-.191-.191-.567-.349-.957.04l-5.506 5.506c-.18.18-.635.127-.976-.214-.098-.097-.576-.613-.213-.973l7.915-7.917c.818-.817 2.267-.699 3.23.262.5.501.802 1.1.849 1.685.051.573-.156 1.111-.589 1.543l-9.547 9.549a3.97 3.97 0 0 1-2.829 1.171 3.975 3.975 0 0 1-2.83-1.173 3.973 3.973 0 0 1-1.172-2.828c0-1.071.415-2.076 1.172-2.83l7.209-7.211c.157-.157.264-.579.028-.814L11.5 4.36a.572.572 0 0 0-.834.018l-7.205 7.207a5.577 5.577 0 0 0-1.645 3.971z"
                      ></path></svg
                  ></template>
                  <b-dropdown-item @click="sendLocationMessage"
                    ><b-icon-geo-alt /> Location</b-dropdown-item
                  >
                  <b-dropdown-item @click="$refs.mediaUpload.click()"
                    ><b-icon-folder /> Files</b-dropdown-item
                  >
                </b-dropdown>
                <input
                  type="file"
                  style="display: none"
                  name="media"
                  ref="mediaUpload"
                  id="media_file"
                  @change="sendMediaMessage($event)"
                />
              </div>
              <div class="search-filt-bar pull-right">
                <div class="search-grey-bx">
                  <form @submit.prevent="searchInConversation">
                    <input
                      type="text"
                      class="conversation-search-field"
                      v-model="conversationSearch"
                      placeholder="Search in conversation"
                    />
                    <a
                      v-if="conversationSearch == ''"
                      href="javascript:void(0);"
                      class="icon conversation-bt-search"
                      ><b-icon-search
                    /></a>
                    <a
                      v-if="conversationSearch != ''"
                      @click="resetSearch"
                      href="javascript:void(0);"
                      class="icon conversation-bt-search"
                      ><b-icon-x-circle
                    /></a>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </header>
        <section
          class="active-filters"
          v-if="filterTaggedMessages || filterMediaMessages"
        >
          <div>
            Filters:
            <b-badge v-if="filterTaggedMessages" pill variant="secondary"
              >Tagged Messages <b-icon-x-circle @click="taggedMessages(false)"
            /></b-badge>
            <b-badge v-if="filterMediaMessages" pill variant="secondary"
              >Media, links & docs
              <b-icon-x-circle @click="mediaMessages(false)"
            /></b-badge>
          </div>
        </section>
        <section
          class="active-filters"
          v-if="conversation.admin_tookover === 1 && session.role === 'Manager'"
        >
          <div>
            Takeover in progress
            <b-badge
              v-if="session.id === conversation.user_id"
              pill
              variant="secondary"
              >Leave <b-icon-x-circle @click="takeover(0)"
            /></b-badge>
          </div>
        </section>
        <!-- end active header-->
        <section class="chat-content-bl" ref="chatContainer">
          <div id="chat-messages" class="chat-cont-area">
            <infinite-loading
              direction="top"
              @infinite="fetch"
              ref="infiniteLoading"
            ></infinite-loading>
            <div
              v-for="message in messages"
              :key="message.id"
              v-bind:data-message-id="message.id"
            >
              <div
                class="chat-row"
                :class="message.is_sent ? 'send' : 'receive'"
              >
                <div
                  class="message-box"
                  :class="
                    message.msg_template_id != null ? 'template-message' : ''
                  "
                >
                  <div v-if="message.tags.length > 0" class="selected-tags">
                    <i
                      v-for="tag in message.tags"
                      class="circle"
                      :data-message-tag-id="tag.tag.id"
                      :style="{ 'background-color': tag.tag.colour }"
                      :key="tag.id"
                    ></i>
                  </div>
                  <div class="dropdown chat-act message-opt">
                    <message-options
                      :bus="bus"
                      :message="message"
                      :key="message.id"
                      :tags="session.tags"
                      @delete="deleteMessage"
                    >
                    </message-options>
                  </div>
                  <replied-to
                    v-if="message.replied_to"
                    :message="message.replied_to"
                    :message-layout="
                      getMessageTemplate(
                        message.replied_to.message_type,
                        message.replied_to.media_content_type
                      )
                    "
                  ></replied-to>
                  <message-types
                    :message="message"
                    :message-layout="
                      getMessageTemplate(
                        message.message_type,
                        message.media_content_type
                      )
                    "
                    :key="message.id"
                  ></message-types>

                  <div class="chat-status">
                    <dt>
                      {{ message.created_at | chatLocalDate }}
                      <b-icon-check2-all
                        v-if="
                          message.status == 'Delivered' ||
                          message.status == 'Accepted'
                        "
                      />
                      <b-icon-check2-all
                        :style="{ color: 'blue' }"
                        v-if="message.status == 'Read'"
                      />
                      <b-icon-info-circle
                        :style="{ color: 'red' }"
                        v-if="message.status == 'Failed'"
                      />
                    </dt>
                  </div>
                </div>
              </div>
              <div
                v-if="message.display_date != null"
                class="message-date-group"
              >
                <b-badge>{{ message.display_date }}</b-badge>
              </div>
            </div>
            <div class="new-messages-container" v-if="newMessages > 0">
              <div class="new-messages-indicator" @click="scrollToBottom">
                <b-icon-arrow-down />
                New Messages({{ newMessages }})
              </div>
            </div>
          </div>
        </section>
      </div>
      <textarea id="clipboard"></textarea>
      <footer class="user-msg-box">
        <form id="send-message" @submit.prevent="sendTextMessage">
          <!-- Templates -->
          <message-templates
            v-if="conversation.messageFormType === 'templateForm'"
            @sendTemplate="sendTemplateMessage"
            @sendTemplateMedia="sendMediaMessageFromTemplate"
          />
          <!-- Send message form -->
          <div v-if="conversation.messageFormType === 'textForm'">
            <div class="user-act-btm">
              <a
                href="javascript:void(0);"
                @click="toggleAudioModal = true"
                class="icon-voice conversation-mic"
              >
                <b-icon-mic-fill />
              </a>
              <a
                href="javascript:void(0);"
                @click="toggleScheduleModal = true"
                class="icon-voice conversation-mic"
              >
                <b-icon-watch />
              </a>
              <a
                href="javascript:void(0);"
                @click="toggleUserTemplateModal = true"
                class="icon-voice conversation-mic"
              >
                <b-icon-lightning-fill />
              </a>
              <b-dropdown dropup toggle-class="emoji-icon" no-caret>
                <template #button-content>
                  <b-icon-emoji-smile-fill />
                </template>
                <b-dropdown-text class="emoji-container">
                  <VEmojiPicker @select="selectEmoji" />
                </b-dropdown-text>
              </b-dropdown>
              <audio
                controls
                playsinline
                class="conversation-audio"
                style="display: none"
              ></audio>
              <div id="after-microphone-div" style="display: none">
                <a href="javascript:void(0);" id="conversation-mic-right"
                  ><i class="fas fa-check-circle text-success fa-2x"></i
                ></a>
                <a href="javascript:void(0);" id="conversation-mic-wrong"
                  ><i class="fas fa-times-circle text-danger fa-2x"></i
                ></a>
              </div>
              <textarea
                v-model="messageBody"
                ref="messageBox"
                @keydown.space="preventLeadingSpace"
                @keydown.enter.exact.prevent
                @keyup.enter.exact="sendTextMessage"
                @keydown.enter.shift.exact="pressEnter"
                class="form-control conversation-text"
                placeholder="Type a message (Shift + Enter for new line)"
                autocomplete="off"
                :style="{ height: textAreaHeight + 'px' }"
              >
              </textarea>

              <ul
                class="nav nav-tabs tablist"
                id="conversation-actions"
                role="tablist"
              >
                <li v-if="messageBody != ''" class="nav-item icon-send">
                  <button type="submit" class="fa fa-paper-plane">
                    <b-icon-cursor-fill />
                  </button>
                </li>
                <!--                                <li v-if="messageBody == ''" class="nav-item icon-gif-sticker">
                                    <a class="icon icon-gif" id="gif-tab" data-toggle="tab" href="#gif" role="tab"
                                       aria-controls="home" aria-selected="true"></a>
                                </li>
                                <li v-if="messageBody == ''" class="nav-item icon-gif-sticker">
                                    <a class="icon icon-sticker" id="giphy-tab" data-toggle="tab" href="#giphy" role="tab"
                                       aria-controls="profile" aria-selected="false"></a>
                                </li>-->
              </ul>
            </div>
            <div v-if="stickers" class="tab-content">
              <div
                class="tab-pane"
                id="gif"
                role="tabpanel"
                aria-labelledby="gif-tab"
              >
                <div class="gif-anim-bl">
                  <div class="gif-bl"><img src="images/icon-burger.png" /></div>
                  <div class="gif-bl">
                    <img src="images/icon-burger2.png" />
                  </div>
                  <div class="gif-bl"><img src="images/icon-burger.png" /></div>
                  <div class="gif-bl">
                    <img src="images/icon-burger2.png" />
                  </div>
                  <div class="gif-bl"><img src="images/icon-burger.png" /></div>
                  <div class="gif-bl">
                    <img src="images/icon-burger2.png" />
                  </div>
                  <div class="gif-bl"><img src="images/icon-burger.png" /></div>
                  <div class="gif-bl">
                    <img src="images/icon-burger2.png" />
                  </div>
                  <div class="gif-bl"><img src="images/icon-burger.png" /></div>
                  <div class="gif-bl">
                    <img src="images/icon-burger2.png" />
                  </div>
                  <div class="gif-bl"><img src="images/icon-burger.png" /></div>
                  <div class="gif-bl">
                    <img src="images/icon-burger2.png" />
                  </div>
                  <div class="gif-bl">
                    <img src="images/icon-burger2.png" />
                  </div>
                  <div class="gif-bl"><img src="images/icon-burger.png" /></div>
                  <div class="gif-bl">
                    <img src="images/icon-burger2.png" />
                  </div>
                  <div class="gif-bl"><img src="images/icon-burger.png" /></div>
                  <div class="gif-bl">
                    <img src="images/icon-burger2.png" />
                  </div>
                </div>
              </div>
              <div
                class="tab-pane"
                id="giphy"
                role="tabpanel"
                aria-labelledby="giphy-tab"
              >
                <div class="stick-anim-bl">
                  <div class="srch-gipsy">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Search Giphy"
                    />
                  </div>
                  <div class="stick-blocks">
                    <div class="stick-bl">
                      <img
                        src="https://media0.giphy.com/media/cluoH97qdlBd56Izol/100w.webp?cid=ecf05e47832aa84589a9b710942ee684111401d9245e9435&rid=100w.webp"
                      />
                    </div>
                    <div class="stick-bl">
                      <img
                        src="https://media2.giphy.com/media/chVRlpAL9RTPvS1b46/200w.webp?cid=ecf05e47832aa84589a9b710942ee684111401d9245e9435&rid=200w.webp"
                      />
                    </div>
                    <div class="stick-bl">
                      <img
                        src="https://media4.giphy.com/media/lRLH2KwSgJDjjWbNXu/200.webp?cid=bb5a1c3a800e73d265579bc561e6e469232e2ebd686125bd&rid=200.webp"
                      />
                    </div>
                    <div class="stick-bl">
                      <img
                        src="https://media0.giphy.com/media/cluoH97qdlBd56Izol/100w.webp?cid=ecf05e47832aa84589a9b710942ee684111401d9245e9435&rid=100w.webp"
                      />
                    </div>
                    <div class="stick-bl">
                      <img
                        src="https://media2.giphy.com/media/chVRlpAL9RTPvS1b46/200w.webp?cid=ecf05e47832aa84589a9b710942ee684111401d9245e9435&rid=200w.webp"
                      />
                    </div>
                    <div class="stick-bl">
                      <img
                        src="https://media4.giphy.com/media/lRLH2KwSgJDjjWbNXu/200.webp?cid=bb5a1c3a800e73d265579bc561e6e469232e2ebd686125bd&rid=200.webp"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
        <div
          v-if="conversation.messageFormType === 'available'"
          class="assigned-user text-center"
        >
          <small><i>Available</i></small>
          <button class="btn-sm btn-primary" @click="assignment(1)">
            Assign to me
          </button>
        </div>
        <div
          v-if="
            conversation.messageFormType === 'assigned' &&
            session.role === 'Manager'
          "
          class="assigned-user text-center"
        >
          {{
            conversation.admin_tookover === 0 ? "Assigned To" : "Tookover by "
          }}
          <b>{{ conversation.user.name }}</b>
          <button class="btn-sm btn-primary" @click="takeover(1)">
            Takeover
          </button>
        </div>
        <div
          v-if="
            conversation.messageFormType === 'assigned' &&
            session.role === 'Agent'
          "
          class="assigned-user text-center"
        >
          {{
            conversation.admin_tookover === 0 ? "Assigned To" : "Tookover by "
          }}
          <b>{{ conversation.user.name }}</b>
        </div>
        <!-- assignment details -->
      </footer>
    </main>
    <transition name="fade" :duration="1500">
      <template>
        <div>
          <b-sidebar id="contact-info" title="Contact Info" right shadow>
            <div class="px-3 py-2">
              <div style="position: relative">
                <b-img
                  :src="conversation.influencer.display_picture"
                  fluid
                  thumbnail
                ></b-img>
                <div class="contact-details">
                  <div class="contact-details-name">
                    {{ conversation.influencer.name }}
                    <br />{{ conversation.influencer.phone }}
                  </div>
                </div>
              </div>
              <table class="table bg-white">
                <tr>
                  <td>
                    <b-button
                      block
                      variant="default"
                      @click="taggedMessages"
                      class="text-left"
                      :style="
                        this.filterTaggedMessages
                          ? 'background-color: #ccc'
                          : ''
                      "
                      ><b-icon-tags-fill />&nbsp;<span
                        >Tagged messages</span
                      ></b-button
                    >
                  </td>
                </tr>
                <tr>
                  <td>
                    <b-button
                      block
                      variant="default"
                      @click="mediaMessages"
                      class="text-left"
                      :style="
                        this.filterMediaMessages ? 'background-color: #ccc' : ''
                      "
                      ><b-icon-image />&nbsp;<span
                        >Media, Links & Docs</span
                      ></b-button
                    >
                  </td>
                </tr>
                <tr>
                  <td>
                    <b-button
                      block
                      variant="danger"
                      @click="assignmentRemove(0)"
                      v-if="conversation.user_id == session.id"
                      >REMOVE FROM ME</b-button
                    >
                    <b-button
                      block
                      variant="success"
                      @click="assignment(1)"
                      v-if="conversation.user_id == null"
                      >ASSIGN TO ME</b-button
                    >
                    <b-button
                      block
                      variant="warning"
                      @click="assignmentRequest()"
                      v-if="
                        conversation.user_id != session.id &&
                        conversation.user_id != null
                      "
                      >REQUEST</b-button
                    >
                  </td>
                </tr>
              </table>
            </div>
          </b-sidebar>
        </div>
      </template>
    </transition>
    <!-- Modals -->
    <b-modal
      v-model="toggleAudioModal"
      hide-footer
      size="sm"
      title="Voice Message"
    >
      <audio-recorder
        :time="2"
        :show-upload-button="false"
        :show-download-button="false"
        :after-recording="sendVoiceMessage"
        :stop-record="sendVoiceMessage"
      />
    </b-modal>
    <b-modal v-model="toggleUserTemplateModal" hide-footer title="Templates">
      <UserTemplatesComponent @receiveUserTemplate="sendUserTemplate" />
    </b-modal>
    <b-modal
      v-model="toggleLocationModal"
      hide-footer
      hide-header
      size="sm"
      title="Sending Location"
    >
      <div class="text-center">
        <div>
          <b-iconstack font-scale="5" animation="spin">
            <b-icon
              stacked
              icon="b-icon-geo-alt"
              variant="success"
              scale="0.75"
              shift-v="-0.25"
            ></b-icon>
            <b-icon stacked icon="circle" variant="success"></b-icon>
          </b-iconstack>
        </div>
        <div>Fetching your current location</div>
      </div>
    </b-modal>
    <b-modal
      v-model="toggleClipboardModal"
      title="Image Preview"
      hide-footer
      hide-header
      size="lg"
      :no-close-on-backdrop="true"
      :content-class="'photo-preview-modal-content'"
      :body-class="'photo-preview-modal'"
    >
      <div class="text-center">
        <div class="clipboard-preview-buttons">
          <b-button size="sm" @click="sendClipboardMedia"
            >Send <b-icon-cursor-fill
          /></b-button>
          &nbsp;&nbsp;<b-button size="sm" @click="toggleClipboardModal = false"
            >Close</b-button
          >
        </div>
        <img class="photo-preview-img" :src="clipboardPreviewTmpUrl" />
      </div>
    </b-modal>

    <b-modal
      v-model="toggleScheduleModal"
      size="lg"
      hide-footer
      title="Schedule Message"
    >
      <Schedule
        :conversation-id="conversation.id"
        :message="this.messageBody"
      />
    </b-modal>
  </div>
</template>
<script>
import InfiniteLoading from "vue-infinite-loading";
import { VEmojiPicker } from "v-emoji-picker";
//import Pusher from "pusher-js";
import moment from "moment";
import MessageOptions from "./MessageOptions";
import linkifyHtml from "linkify-html";
import UserTemplatesComponent from "./UserTemplates/UserTemplatesComponent";
import RepliedTo from "./Message/RepliedTo";
import MessageTypes from "./Message/MessageTypes";
import MessageTemplates from "./Message/MessageTemplates";
import Schedule from "./Message/Schedule";

export default {
  name: "ChatComponent",
  components: {
    Schedule,
    MessageTemplates,
    MessageTypes,
    RepliedTo,
    UserTemplatesComponent,
    MessageOptions,
    InfiniteLoading,
    VEmojiPicker,
  },
  props: ["conversation", "bus", "contactInfo"],
  data() {
    return {
      emojiValue: "",
      textAreaHeight: 48,
      session: {},
      newMessages: 0,
      filterTaggedMessages: false,
      filterMediaMessages: false,
      toggleUserTemplateModal: false,
      toggleClipboardModal: false,
      toggleAudioModal: false,
      toggleLocationModal: false,
      toggleScheduleModal: false,
      /*photoPreview: {
            src: '',
            modal: false
          },*/
      stickers: false,
      conversationSearch: "",
      messages: [],
      messageBody: "",
      busy: false,
      lastMessageId: 0,
      mediaAudio: [
        "audio/ogg; codecs=opus",
        "m4a",
        "audio/mpeg",
        "audio/aac",
        "audio/x-m4a",
        "audio/mpeg",
        "audio/wav",
        "audio/mp3",
      ],
      mediaPhoto: ["png", "jpg", "image/jpeg", "image/png"],
      mediaGif: ["gif", "GIF"],
      mediaVideo: ["video/mp4", "mkv", "video/quicktime"],
      clipboardPreview: null,
      clipboardPreviewTmpUrl: null,
    };
  },
  mounted() {
    if (localStorage.getItem("session") !== null) {
      this.session = JSON.parse(localStorage.getItem("session"));
    }
    this.bus.$on("newPusher", this.newPusherMessage);
    this.bus.$on("tagApplied", (message) => {
      let messageIndex = this.messages.findIndex((x) => x.id === message.id);
      this.messages[messageIndex].tags = message.tags;
    });
  },
  methods: {
    deleteMessage(message) {
      if (confirm("Are you sure, You want to delete this message?")) {
        this.$http
          .post("/message/action/archive", {
            msg_id: message.id,
            value: 1,
          })
          .then((response) => {
            if (response.data.result) {
              let messageIndex = this.messages.findIndex(
                (x) => x.id === message.id
              );
              if (this.messages.length - 1 === messageIndex) {
                this.conversation.last_message =
                  this.messages[messageIndex - 1].body;
                this.conversation.last_message_time =
                  this.messages[messageIndex - 1].created_at;
              }
              this.messages.splice(messageIndex, 1);
            } else {
              console.error(response.data.message);
            }
          });
      }
    },
    setEmoji(emoji) {
      console.log(emoji);
    },
    setGif(gif) {
      console.log(gif);
    },
    pressEnter: function () {
      this.messageBody = `${this.messageBody}`;
      this.textAreaHeight = this.textAreaHeight + 20;
    },
    fetch: function ($state) {
      this.busy = true;
      let date = "";
      this.$http
        .post(
          `/web/message/list/${this.conversation.id}/${this.lastMessageId}`,
          {
            keyword: this.conversationSearch,
            taggedMessages: this.filterTaggedMessages,
            mediaMessages: this.filterMediaMessages,
          }
        )
        .then((response) => {
          let sentCount = 0,
            receiveCount = 0;
          if (response.data.result) {
            response.data.data.reverse();
            if (date === "" && response.data.data.length > 0) {
              date = moment
                .utc(response.data.data[0].created_at)
                .local()
                .format("MMMM D, YYYY");
              this.lastMessageId =
                response.data.data[response.data.data.length - 1].id;
              for (let i = 0; i < response.data.data.length; i++) {
                if (
                  response.data.data[i].body !== "" &&
                  response.data.data[i].body !== null
                ) {
                  let msgBody = linkifyHtml(response.data.data[i].body, {
                    target: "_blank",
                  });
                  response.data.data[i].body = msgBody;
                }
                response.data.data[i].messageLayout = this.getMessageTemplate(
                  response.data.data[i].message_type,
                  response.data.data[i].media_content_type
                );
                let created_at = moment
                  .utc(response.data.data[i].created_at)
                  .local();
                if (date !== created_at.format("MMMM D, YYYY")) {
                  response.data.data[i]["display_date"] = date;
                  date = created_at.format("MMMM D, YYYY");
                } else {
                  response.data.data[i]["display_date"] = null;
                }
                //response.data.data[i]['created_at'] = created_at.format('h:mm A');
                this.messages.unshift(response.data.data[i]);
                if (response.data.data[i].is_sent) {
                  sentCount++;
                } else {
                  receiveCount++;
                }
              }
              $state.loaded();
            } else {
              $state.complete();
            }
          } else {
            $state.complete();
          }
          if (this.conversationSearch !== "") {
            this.MixPanelTrack("Conversation Searched", {
              "Search Term": this.conversationSearch,
              "Total # of Results Returned": response.data.data.length,
              "# of Sent Messages": sentCount,
              "# of Received Messages": receiveCount,
            });
          }
        })
        .catch((error) => {
          this.errorHandler(error);
        });
    },
    searchInConversation: function () {
      this.messages = [];
      this.lastMessageId = 0;
      this.$nextTick(() => {
        this.$refs.infiniteLoading.$emit("$InfiniteLoading:reset");
      });
    },
    resetSearch: function () {
      this.conversationSearch = "";
      this.messages = [];
      this.lastMessageId = 0;
      this.$nextTick(() => {
        this.$refs.infiniteLoading.$emit("$InfiniteLoading:reset");
      });
    },
    newPusherMessage: function (data) {
      data.tags = [];
      this.newMessages = this.newMessages + 1;
      data.messageLayout = this.getMessageTemplate(
        data.message_type,
        data.media_content_type
      );
      if (this.messages.length > 0) {
        let lastMessage = this.messages[this.messages.length - 1];
        if (lastMessage.message_type === "template") {
          this.MixPanelTrack("Template Responded", {
            Template: lastMessage.body,
            "Contact Name": data.influencer.name,
            Organization: this.Organization(this.conversation.phone_home),
            $name: this.session.name,
            "Message Type": data.message_type,
            "Response Text": data.body,
          });
        }
      }
      this.messages.push(data);
      //this.scrollToBottom();
    },
    getMessageTemplate: function (messageType, mediaContentType) {
      let messageLayout = "text";
      switch (messageType) {
        case "template":
        case "text":
        case "contact":
        case "location":
          messageLayout = messageType;
          break;
        case "media":
          if (this.mediaPhoto.includes(mediaContentType)) {
            messageLayout = "mediaPhoto";
          } else if (this.mediaGif.includes(mediaContentType)) {
            messageLayout = "mediaGif";
          } else if (this.mediaAudio.includes(mediaContentType)) {
            messageLayout = "mediaAudio";
          } else if (this.mediaVideo.includes(mediaContentType)) {
            messageLayout = "mediaVideo";
          } else {
            messageLayout = "mediaDefault";
          }
          break;
      }
      return messageLayout;
    },
    sendMediaMessageFromTemplate: function (args) {
      this.sendMediaMessage(args.event, args.templateId);
    },
    sendMediaMessage: function (event, msgTemplateId = null) {
      let form = new FormData(),
        file = event.target.files[0],
        tmpPath = URL.createObjectURL(file),
        dummyId = "tmp_" + (this.messages.length + 1);
      if (msgTemplateId) {
        if (file.type !== "application/pdf") {
          this.$bvToast.toast(
            `Only PDF allowed to share in document templates`,
            {
              variant: "danger",
              title: "Invalid File",
            }
          );
          return false;
        }
        form.append("msg_template_id", msgTemplateId);
      }
      // Displaying file
      let message = {
        id: dummyId,
        message_type: "media",
        media_content_type: file.type,
        body: "",
        conversation_id: this.conversation_id,
        media_url: tmpPath,
        media_file_name: file.name,
        status: "Pending",
        created_at: moment().format("YYYY-MM-DD HH:mm:ss"),
        tags: [],
        messageLayout: this.getMessageTemplate("media", file.type),
        is_sent: 1,
      };
      this.messages.push(message);
      this.scrollToBottom();

      // this.conversation.last_message = "Media File";
      // this.conversation.last_message_time = moment.now();
      let messageReq = {};
      // uploading file

      form.append("body", "");
      form.append("media", file);
      form.append("conversation_id", this.conversation.id);
      form.append("file_name", file.name);
      this.$http.post("/message/send", form).then((response) => {
        if (response.data.result) {
          let messageIndex = this.messages.findIndex((x) => x.id === dummyId);
          this.messages[messageIndex].id = response.data.data.id;
          this.messages[messageIndex].created_at =
            response.data.data.created_at;
          this.messages[messageIndex].status = response.data.data.status;
          this.messages[messageIndex].media_url = response.data.data.media_url;
          this.conversation.last_message_time =
            response.data.data.message_created;
          if (
            response.data.data.media_content_type.substring(0, 5) == "video"
          ) {
            messageReq = {
              icon: "camera-video-fill",
              conversation_id: response.data.data.conversation_id,
              last_message: "Video",
            };
          } else if (
            response.data.data.media_content_type.substring(0, 5) == "image"
          ) {
            messageReq = {
              icon: "camera-fill",
              conversation_id: response.data.data.conversation_id,
              last_message: "Photo",
            };
          } else if (
            response.data.data.media_content_type.substring(0, 5) == "audio"
          ) {
            messageReq = {
              icon: "music-note-beamed",
              conversation_id: response.data.data.conversation_id,
              last_message: "Audio",
            };
          }
          this.bus.$emit("conversationMessageAccepted", messageReq);
        } else {
          //Vue.toast("Failed to upload file");
        }
        this.sendMessageMixPanel(response.data.data);
      });
    },
    sendLocationMessage: function () {
      this.toggleLocationModal = true;
      if (!("geolocation" in navigator)) {
        //console.log('Geolocation is not available.');
        this.toggleLocationModal = false;
        return;
      }
      // get position
      this.conversation.icon = "geo-alt-fill";
      this.conversation.last_message = "Location";
      // this.conversation.last_message_time = moment.now();
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          this.$http
            .post("/message/send", {
              conversation_id: this.conversation.id,
              body: "",
              latitude: pos.coords.latitude,
              longitude: pos.coords.longitude,
            })
            .then((response) => {
              if (response.data.result) {
                this.conversation.last_message = "Location";
                this.conversation.last_message_time =
                  response.data.data.message_created;
                response.data.data.messageLayout = "location";
                let messageReq = {
                  icon: "geo-alt-fill",
                  conversation_id: response.data.data.conversation_id,
                  last_message: "Location",
                };
                this.bus.$emit("conversationMessageAccepted", messageReq);
                this.messages.push(response.data.data);
                this.scrollToBottom();
              } else {
                //toastr.error(response.data.message);
              }
              this.toggleLocationModal = false;
              this.sendMessageMixPanel(response.data.data);
            });
        },
        (err) => {
          this.toggleLocationModal = false;
          console.log(err.message);
        }
      );
    },
    sendVoiceMessage: function (data) {
      let file = data.blob,
        tmpPath = data.url,
        dummyId = "tmp_" + (this.messages.length + 1);
      let message = {
        id: dummyId,
        message_type: "media",
        media_content_type: "audio/mpeg",
        body: "",
        conversation_id: this.conversation_id,
        media_url: tmpPath,
        media_file_name: data.id,
        status: "Pending",
        created_at: moment.now(),
        tags: [],
        messageLayout: this.getMessageTemplate("media", "audio/mpeg"),
        is_sent: 1,
      };
      this.messages.push(message);
      this.scrollToBottom();
      this.toggleAudioModal = false;
      this.conversation.icon = "music-note-beamed";
      this.conversation.last_message = "Audio";
      // this.conversation.last_message = "Voice Message";
      // this.conversation.last_message_time = message.created_at;
      // uploading file
      let form = new FormData();
      form.append("body", "");
      form.append("media", file);
      form.append("conversation_id", this.conversation.id);
      form.append("file_name", file.name);
      this.$http.post("/message/send", form).then((response) => {
        if (response.data.result) {
          let messageIndex = this.messages.findIndex((x) => x.id === dummyId);
          this.messages[messageIndex].id = response.data.data.id;
          //this.messages[messageIndex].created_at = moment.utc(response.data.data.created_at).local().format("H:mm A");
          this.messages[messageIndex].status = response.data.data.status;
          this.messages[messageIndex].media_url = response.data.data.media_url;
          this.conversation.last_message_time =
            response.data.data.message_created;
          let messageReq = {
            icon: "music-note-beamed",
            conversation_id: response.data.data.conversation_id,
            last_message: "Audio",
          };
          this.bus.$emit("conversationMessageAccepted", messageReq);
        } else {
          //Vue.toast("Failed to upload file");
        }
        this.sendMessageMixPanel(response.data.data);
      });
    },
    sendUserTemplate: function (message) {
      this.messageBody = message;
      this.toggleUserTemplateModal = false;
      this.sendTextMessage();
    },
    sendTextMessage: function () {
      if (this.messageBody == "" || this.messageBody == null) {
        this.$bvToast.toast(`Message Should not be blank`, {
          variant: "danger",
          title: "Error",
        });
        return;
      }
      let dummyId = "tmp_" + (this.messages.length + 1),
        messageBody = this.messageBody,
        linkifyBody = linkifyHtml(this.messageBody);
      let message = {
        id: dummyId,
        message_type: "text",
        body: linkifyBody,
        conversation_id: this.conversation_id,
        status: "Pending",
        created_at: moment.now(),
        tags: [],
        messageLayout: this.getMessageTemplate("text"),
        is_sent: 1,
      };
      this.messages.push(message);
      this.conversation.icon = "";
      this.conversation.last_message = messageBody;
      // this.conversation.last_message_time = message.created_at;
      this.messageBody = "";
      this.textAreaHeight = 50;
      this.scrollToBottom();
      messageBody = messageBody.replaceAll("<b>", "*");
      messageBody = messageBody.replaceAll("</b>", "*");
      this.$http
        .post("/message/send", {
          conversation_id: this.conversation.id,
          body: messageBody,
        })
        .then((response) => {
          let messageIndex = this.messages.findIndex((x) => x.id === dummyId);
          if (response.data.result) {
            this.messages[messageIndex].id = response.data.data.id;
            //this.messages[messageIndex].created_at = moment.utc(response.data.data.created_at).local().format('H:mm A');
            this.messages[messageIndex].status = response.data.data.status;
            this.messages[messageIndex].messageLayout = "text";
            this.conversation.last_message_time =
              response.data.data.message_created;
            this.conversation.icon = "";
            this.conversation.last_message = response.data.data.body;
            let messageReq = {
              icon: "",
              conversation_id: response.data.data.conversation_id,
              last_message: response.data.data.body,
            };
            this.bus.$emit("conversationMessageAccepted", messageReq);
          } else {
            //toastr.error(response.data.message);
          }
          this.sendMessageMixPanel(response.data.data);
        });
    },
    sendTemplateMessage: function (template) {
      var body = template.templates;
      let params = {
        msg_template_id: template.id,
        conversation_id: this.conversation.id,
        body: body,
      };
      if (template.type === "document") {
        params = {
          msg_template_id: template.id,
          conversation_id: this.conversation.id,
          body: body,
        };
      }

      this.$http.post("/message/send", params).then((response) => {
        if (response.data.result) {
          this.conversation.icon = "";
          this.conversation.last_message = body;
          this.conversation.last_message_time =
            response.data.data.message_created;
          response.data.data.messageLayout = "template";
          this.messages.push(response.data.data);
          this.scrollToBottom();
          /**
           * Mix Panel
           */
          this.MixPanelTrack("Template Sent", {
            Template: template.templates,
            "Contact Name": this.conversation.influencer.name,
            Organization: this.Organization(this.session.organization_phone),
            $name: this.session.name,
          });
        } else {
          //toastr.error(response.data.message);
        }
      });
    },
    scrollToBottom: function () {
      this.newMessages = 0;
      var container = this.$el.querySelector(".chat-content-bl");
      this.$nextTick(function () {
        container.scrollTop = container.scrollHeight;
      });
    }, // Contact methods
    /*openContactInfo: function () {
          this.contactInfo = true;
          this.bus.$emit('toggleThreePanel', true);
        },*/
    selectEmoji(emoji) {
      let textarea = this.$refs.messageBox;
      //this.messageBody = this.messageBody + emoji.data
      var startPos = textarea.selectionStart,
        endPos = textarea.selectionEnd,
        cursorPos = startPos,
        tmpStr = textarea.value;
      this.messageBody =
        tmpStr.substring(0, startPos) +
        emoji.data +
        tmpStr.substring(endPos, tmpStr.length);
      setTimeout(() => {
        cursorPos += emoji.data.length;
        textarea.selectionStart = textarea.selectionEnd = cursorPos;
      }, 10);
    },

    /*closeContactInfo: function () {
            this.contactInfo = false;
            this.bus.$emit('toggleThreePanel', false);
        },*/
    assignment: function (value) {
      this.$http
        .post(
          "/conversation/action/assign",
          {
            conversation_id: this.conversation.id,
            value: value,
          },
          this.apiConfig
        )
        .then((response) => {
          if (response.data.result) {
            let labels = [];
            if (this.conversation.labels.length > 0) {
              this.conversation.labels.map((label) => {
                let labelIndex = this.session.labels.findIndex(
                  (x) => x.id === label.label_id
                );
                labels.push(this.session.labels[labelIndex].name);
              });
            }

            //this.conversation = response.data.data;
            this.MixPanelTrack("Conversation Adopted", {
              "Contact Name": this.conversation.influencer.name,
              $name: this.session.name,
              Organization: this.Organization(this.session.organization_phone),
              "Conversation Label": labels,
            });

            this.conversation.user = response.data.data.user;
            this.conversation.user_id = response.data.data.user_id;
            this.conversation.original_user_id =
              response.data.data.original_user_id;
            this.conversation.messageLayout = "template";
            this.bus.$emit("conversationAdopted", this.conversation);
          } else {
            //toastr.error("Could not assign conversation");
          }
        })
        .catch((error) => {
          console.error(this.conversation, error);
          //this.errorHandler(error)
        });
    },
    assignmentRemove: function (value) {
      this.$bvModal
        .msgBoxConfirm("Are you sure, you want to un-assign this conversation?")
        .then((v) => {
          if (v) {
            this.$http
              .post(
                "/conversation/action/assign",
                {
                  conversation_id: this.conversation.id,
                  value: value,
                },
                this.apiConfig
              )
              .then((response) => {
                if (response.data.result) {
                  //this.conversation = response.data.data;
                  let labels = [];
                  if (this.conversation.labels.length > 0) {
                    this.conversation.labels.map((label) => {
                      let labelIndex = this.session.labels.findIndex(
                        (x) => x.id === label.label_id
                      );
                      labels.push(this.session.labels[labelIndex].name);
                    });
                  }

                  //this.conversation = response.data.data;
                  this.MixPanelTrack("Conversation Removed", {
                    "Contact Name": this.conversation.influencer.name,
                    $name: this.session.name,
                    Organization: this.Organization(
                      this.session.organization_phone
                    ),
                    "Conversation Label": labels,
                  });
                  this.conversation.user = response.data.data.user;
                  this.conversation.user_id = response.data.data.user_id;
                  this.conversation.original_user_id =
                    response.data.data.original_user_id;
                  this.conversation.messageLayout = "template";
                  this.bus.$emit("conversationReload");
                } else {
                  //toastr.error("Could not assign conversation");
                }
              });
          }
        })
        .catch((err) => {
          // An error occurred
          console.error(err);
          this.errorHandler(err);
        });
    },
    assignmentRequest: function () {
      this.$bvModal
        .msgBoxConfirm(
          `Are you sure, you want to make assignment request to ${this.conversation.user.name}?`
        )
        .then((v) => {
          if (v) {
            this.$http
              .post(
                "/conversation/assignment/request",
                {
                  conversation_id: this.conversation.id,
                },
                this.apiConfig
              )
              .then((response) => {
                if (response.data.result) {
                  this.MixPanelTrack("Conversation Requested", {
                    "Contact Name": this.conversation.influencer.name,
                    $name: this.session.name,
                    Organization: this.Organization(
                      this.session.organization_phone
                    ),
                    "Assigned User": this.conversation.user.name,
                  });
                  this.$bvToast.toast(`Request was sent successfully`, {
                    variant: "info",
                    title: "Success",
                  });
                } else {
                  this.$bvToast.toast(`${response.data.error.message}`, {
                    variant: "Error",
                    title: "Error",
                  });
                }
              });
          }
        })
        .catch((err) => {
          // An error occurred
          console.error(err);
          this.errorHandler(err);
        });
    },
    taggedMessages: function () {
      this.filterTaggedMessages = !this.filterTaggedMessages;
      this.searchInConversation();
    },
    mediaMessages: function () {
      this.filterMediaMessages = !this.filterMediaMessages;
      this.searchInConversation();
      //toastr.info("This feature is not available yet");
    },
    preventLeadingSpace(e) {
      if (!this.messageBody.length) {
        e.preventDefault();
      }
    },
    takeover(value) {
      var msg = "leave";
      if (value) {
        msg = "takeover";
      }
      this.$bvModal
        .msgBoxConfirm(
          "Are you sure, you want to " + msg + " this conversation?"
        )
        .then((v) => {
          if (v) {
            this.$http
              .post("/conversation/action/tookover", {
                conversation_id: this.conversation.id,
                admin_tookover: value,
              })
              .then((response) => {
                if (response.data.result) {
                  let eventName = "";
                  if (value) {
                    eventName = "Manager Takeover Initiated";
                  } else {
                    eventName = "Manager Takeover Released";
                  }
                  this.MixPanelTrack(eventName, {
                    "Contact Name": this.conversation.influencer.name,
                    $name: this.session.name,
                    Organization: this.Organization(
                      this.session.organization_phone
                    ),
                    "Assigned User": this.conversation.user.name,
                  });
                  this.conversation.user = response.data.data.user;
                  this.conversation.user_id = response.data.data.user_id;
                  this.conversation.original_user_id =
                    response.data.data.original_user_id;
                  this.conversation.messageLayout = "template";
                  this.conversation.admin_tookover = value;
                  this.bus.$emit("takeover");
                } else {
                  //toastr.error(response.data.message);
                }
              });
          }
        })
        .catch((err) => {
          // An error occurred
          console.error(err);
          this.errorHandler(err);
        });
    },
    handlePaste(pasteEvent) {
      if (pasteEvent.clipboardData.files[0].type === "image/png") {
        this.toggleClipboardModal = true;
        this.clipboardPreviewTmpUrl = URL.createObjectURL(
          pasteEvent.clipboardData.files[0]
        );
        try {
          let form = new FormData(),
            file,
            dummyId = "tmp_" + (this.messages.length + 1),
            tmpPath;
          file = pasteEvent.clipboardData.files[0];
          tmpPath = this.clipboardPreviewTmpUrl;

          // Displaying file
          let message = {
            id: dummyId,
            message_type: "media",
            media_content_type: file.type,
            body: "",
            conversation_id: this.conversation_id,
            media_url: tmpPath,
            media_file_name: file.name,
            status: "Pending",
            created_at: moment().format("YYYY-MM-DD HH:mm:ss"),
            tags: [],
            messageLayout: this.getMessageTemplate("media", file.type),
            is_sent: 1,
          };

          form.append("body", "");
          form.append("media", file);
          form.append("conversation_id", this.conversation.id);
          form.append("file_name", file.name);
          this.clipboardPreview = {
            form: form,
            tmpMessage: message,
          };
        } catch (error) {
          this.errorHandler(error);
        }
      }
    },
    sendClipboardMedia() {
      this.messages.push(this.clipboardPreview.tmpMessage);
      this.toggleClipboardModal = false;
      this.scrollToBottom();
      this.conversation.last_message = "Media File";
      // this.conversation.last_message_time = moment.now();
      this.$http
        .post("/message/send", this.clipboardPreview.form)
        .then((response) => {
          if (response.data.result) {
            let messageIndex = this.messages.findIndex(
              (x) => x.id === this.clipboardPreview.tmpMessage.id
            );
            this.messages[messageIndex].id = response.data.data.id;
            this.messages[messageIndex].created_at =
              response.data.data.created_at;
            this.messages[messageIndex].status = response.data.data.status;
            this.messages[messageIndex].media_url =
              response.data.data.media_url;
            this.conversation.last_message_time =
              response.data.data.message_created;
            this.sendMessageMixPanel(response.data.data);
          } else {
            this.toast("Failed to upload file", "error");
          }
          this.clipboardPreview = null;
        })
        .catch((error) => this.errorHandler(error));
    },
    sendMessageMixPanel(message) {
      this.MixPanelTrack("Message Sent", {
        "Contact Name": this.conversation.influencer.name,
        Organization: this.Organization(this.conversation.phone_home),
        $name: this.session.name,
        "Reply?": false,
        "Message Type": message.message_type,
        "Response Text": message.body,
      });
    },
  },
};
</script>

<style scoped>
.chat-user-panel {
  max-height: 100vh !important;
}
</style>
