<template>
    <b-overlay :show="overlay">
        <div>
            <div v-if="showForm">
                <b-row class="mb-3">
                    <b-col><b-form-textarea :state="messageValidation" v-model="form.message"></b-form-textarea></b-col>
                </b-row>
                <b-row>
                    <b-col><b-form-datepicker id="example-datepicker" v-model="form.scheduleDay" class="mb-2"></b-form-datepicker></b-col>
                    <b-col><b-form-timepicker v-model="form.scheduleTime" locale="en"></b-form-timepicker></b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-button type="button" variant="info" @click="add">Save Schedule</b-button>
                        &nbsp;
                        <b-button type="button" @click="showForm=false">Cancel</b-button>
                    </b-col>
                </b-row>
                <b-row>
                    <p>&nbsp;</p>
                </b-row>
            </div>
            <b-row class="mb-3" v-if="!showForm">
                <b-col class="text-right"><b-button type="button" @click="showForm=true">Add</b-button></b-col>
            </b-row>
            <table class="table table-bordered" v-if="schedules.length > 0">
                <tr>
                    <th>User</th>
                    <th>Message</th>
                    <th>Time</th>
                    <th>Status</th>
                    <th>Action</th>
                </tr>
                <tr v-for="schedule in schedules" :key="schedule.id">
                    <td>{{ schedule.conversation.influencer.name }}</td>
                    <td>{{ schedule.message }}</td>
                    <td>{{ schedule.datetime | scheduleDate }}</td>
                    <td>{{ schedule.status === null ? 'Pending' : 'Sent' }}</td>
                    <td>
                        <a href="javascript:void(0);" @click="get(schedule.id)"><b-icon-pencil-fill /></a>
                        <a href="javascript:void(0);" @click="deleteSchedule(schedule.id)"><b-icon-trash-fill /></a>
                    </td>
                </tr>
            </table>
        </div>
    </b-overlay>
</template>

<script>
import moment from "moment"

export default {
    name: "Schedule",
    props: ['conversationId'],
    data() {
        return {
            form: {
                id: null,
                message: "",
                scheduleTime: new Date().toTimeString().slice(0, 8),
                scheduleDay: moment().format("Y-MM-DD"),
            },
            messageValidation:true,
            overlay:true,
            showForm: false,
            schedules: []
        }
    },
    mounted() {
        this.list();
    },
    methods: {
        formReset : function () {
            this.form= {
                id: null,
                message: "",
                scheduleTime: new Date().toTimeString().slice(0, 8),
                scheduleDay: moment().format("Y-MM-DD"),
            }
        },
        list: function () {
            this.overlay = true;
            this.$http.get("/message/schedule")
                .then( response => {
                    this.overlay = false;
                    if(response.data.result) {
                        this.schedules = response.data.data;
                        this.formReset()
                    } else {
                        this.toast("Failed to fetch templates")
                    }
                })
                .catch( error => {
                    this.errorHandler(error)
                    this.overlay = false;
                })
        },
        get: function (id) {
            this.overlay = true;
            this.$http.get(`/message/schedule/${id}/get`)
                .then( response => {
                    this.showForm = true;
                    this.overlay = false;
                    if(response.data.result) {
                        let schedule = response.data.data;
                        this.form = {
                            id: schedule.id,
                            message: schedule.message,
                            scheduleTime: moment.utc(schedule.datetime).local().format("HH:mm"),
                            scheduleDay: moment.utc(schedule.datetime).local().format("Y-MM-DD"),
                        };
                    } else {
                        this.toast("Failed to fetch templates")
                    }
                })
                .catch( error => {
                    this.errorHandler(error)
                    this.overlay = false;
                })
        },
        add: function () {
            if(this.message === "") {
                this.messageValidation = false;
            } else {
                this.overlay = true;
                this.$http.post("/message/schedule",{
                    "id": this.form.id,
                    "body":this.form.message,
                    "conversation_id":this.conversationId,
                    "datetime": moment(this.form.scheduleDay +' '+this.form.scheduleTime).utc().format("Y-MM-DD HH:mm")
                })
                    .then( response => {
                        this.overlay = false;
                        if(response.data.result) {
                            this.schedules = response.data.data;
                            this.showForm = false;
                            this.list();
                        } else {
                            this.toast("Failed to fetch templates")
                        }
                    })
                    .catch( error => {
                        this.errorHandler(error)
                        this.overlay = false;
                    })
            }
        },
        deleteSchedule: function (id) {
            this.$bvModal.msgBoxConfirm('Are you sure?')
                .then(value => {
                    if(value) {
                        this.overlay = true;
                        this.$http.delete(`/message/schedule/${id}/delete`)
                            .then( response => {
                                this.overlay = false;
                                if(response.data.result) {
                                    this.list();
                                } else {
                                    this.toast("Failed to fetch templates")
                                }
                            })
                            .catch( error => {
                                this.errorHandler(error)
                                this.overlay = false;
                            })
                    }
                })
                .catch(err => {
                    console.info(err)
                })
        }
    }
}
</script>

<style scoped>

</style>