<template>
  <b-button
    v-if="takeovers.length > 0"
    size="sm"
    variant="light"
    @click="leaveTakeOvers"
    >Leave all takeovers</b-button
  >
</template>

<script>
export default {
  name: "LeaveAllTakeovers",
  data() {
    return {
      takeovers: [],
    };
  },
  mounted() {
    this.$http
      .get("/conversation/takeovers")
      .then((response) => {
        if (response.data.result) {
          this.takeovers = response.data.data;
        } else {
          console.info("Error marking conversation as read");
        }
      })
      .catch((error) => {
        this.errorHandler(error);
      });
  },
  methods: {
    leaveTakeOvers() {
      this.$bvModal
        .msgBoxConfirm("Are you sure, you want to leave all takeovers?")
        .then((v) => {
          if (v) {
            this.$http
              .post("/conversation/takeovers/leave-all")
              .then((response) => {
                if (response.data.result) {
                  window.location.reload();
                }
              })
              .catch((error) => {
                this.handleError(error);
              });
          }
        })
        .catch((err) => {
          // An error occurred
          console.error(err);
          this.errorHandler(err);
        });
    },
  },
};
</script>

<style scoped>
</style>