<template>
    <select @change="availibilityFilter" v-model="selected">
        <option v-for="(agent,i) in agents" :key="i" :value="agent.key">{{ agent.value }}</option>
    </select>
</template>
<script>
export default {
    name: "AgentFilter",
    data() {
        return {
            agents: [
                {
                    "key" : "me",
                    "value" : "Me",
                },
                {
                    "key" : "available",
                    "value" : "Available",
                },
                {
                    "key" : "all",
                    "value" : "All",
                }
            ],
            selected: 'me'
        }
    },
    mounted() {
        if (localStorage.getItem("session") !== null) {
            let session = JSON.parse(localStorage.getItem('session'))
            if(session.role === 'Manager') {
                this.agents.push({
                    "key" : "me",
                    "value" : "----------------------------",
                })
                this.$http.get('/agent/list').then( response => {
                    if(response.data.result) {
                        response.data.data.map( agent => {
                            this.agents.push({
                                "key" : agent.id,
                                "value" : agent.name,
                            })
                        })
                    } else {
                        this.toast("No users")
                    }
                }).catch( error => this.errorHandler( error ))
            }
        }

    },
    methods: {
        availibilityFilter() {
            this.$emit('availibilityFilter',this.selected);
        }
    }
}
</script>

<style scoped>

</style>