<template>
    <div style="width: 70%;">
        <!-- User chat panel -->
        <main class="right-chat-panel">
            <header class="active-user-panel"></header>
            <section class="chat-content-bl">
                <div class="container d-flex h-100 text-center">
                    <div class="row justify-content-center align-self-center">
                        <img src="@/assets/images/logo-grey.png" class="img-responsive text-center">
                    </div>
                </div>
            </section>
        </main>
    </div>
</template>

<script>
export default {
    name: "EmptyChatWindow"
}
</script>

<style scoped>
    .justify-content-center {
        width: 100%;
    }
</style>
