<template>
    <div>
        <div v-if="messageLayout === 'text' || messageLayout === 'template'" class="chat-text">
            <p v-html="message.body"></p>
        </div>

        <contact-type v-if="messageLayout === 'contact'" :message="message"></contact-type>

        <iframe v-if="messageLayout === 'location'" class="chat-media" :src="'https://www.google.com/maps?q=' + message.latitude + ',' + message.longitude + '&z=17&hl=en&output=embed'" width="100%" height="150" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>

        <div v-if="messageLayout === 'mediaPhoto'">
            <div class="chat-media photo" :style="{ 'background-image': 'url('+message.media_url+')' }">
                <div @click="openPhotoPreview(message.media_url)" class="photo-preview"><b-icon-eye-fill/></div>
            </div>
            <div v-if="message.body !== ''" class="chat-text">
                <p v-html="message.body"></p>
            </div>
            <b-modal v-if="photoPreview.modal" v-model="photoPreview.modal" hide-footer hide-header size="lg"
                     :no-close-on-backdrop="true"
                     :content-class="'photo-preview-modal-content'"
                     :body-class="'photo-preview-modal'" title="Image Preview">
                <div class="text-center">
                    <b-button class="photo-preview-close" size="sm" @click="photoPreview.modal=false;">Close</b-button>
                    <img class="photo-preview-img" :src="photoPreview.src"/>
                </div>
            </b-modal>
        </div>

        <audio v-if="messageLayout === 'mediaAudio'" class="chat-media audio" controls>
            <source :src="message.media_url" type="audio/mp3"/>
        </audio>

        <video v-if="messageLayout === 'mediaVideo'" class="chat-media" controls>
            <source :src="message.media_url" type="video/mp4"/>
        </video>

        <div v-if="messageLayout === 'mediaGif'" class="chat-media" :style="{ 'background-image': 'url('+message.media_url+')' }"></div>

        <a v-if="messageLayout === 'mediaDefault'" target="_blank" :href="message.media_url">
            <div class="file-dt">
                <i><img src="@/assets/images/icon-pdf.jpg"></i>
                <strong>
                    {{ message.media_url.split(".").pop() }}
                    <span>{{ message.media_url.split(".").pop() }} File</span>
                </strong>
            </div>
        </a>
    </div>
</template>

<script>
import ContactType from "./ContactType";
export default {
    name: "MessageTypes",
    components: {ContactType},
    props: ["message","messageLayout"],
    /*mounted() {
        console.info(this.message, this.messageLayout);
    },*/
    data() {
        return {
            photoPreview: {
                src: '',
                modal: false
            }
        }
    },
    methods: {
        openPhotoPreview(src) {
            this.photoPreview.modal = true;
            this.photoPreview.src = src;
        },
    }
}
</script>

<style scoped>

</style>